import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalBgLayout from 'layouts/modal-bg';
import { useDeleteAPlanMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { clearPlansDraft } from 'redux/features/plans/plansSlice';
import Button from 'common/components/button';

const CancelApplicationModal = ({ closeModal, planId }) => {
  // HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteAPlan, { isLoading }] = useDeleteAPlanMutation();

  // HANDLERS
  const handleCancelPlan = () => {
    deleteAPlan(planId)
      .unwrap()
      .then(() => {
        deleteAPlan(planId);
        navigate('/dashboard/plans');
      })
      .catch((error) => {
        toast.error(error?.message || `Couldn't cancel plan`);
      });
    dispatch(clearPlansDraft());
  };

  return (
    <ModalBgLayout>
      <div className='flex flex-col w-full gap-10'>
        {/* icon and texts*/}
        <div className='text-center items-center flex flex-col gap-6'>
          <FaInfoCircle className='text-7xl text-[#191923]' />
          <p className='text-[#191923] text-3xl font-bold'>
            Cancel Application
          </p>
          <p className='font-normal text-xl text-[#191923]'>
            Cancelling the application will delete the application and you will
            need to start again. Proceed if you want to cancel and delete the
            plan application.
          </p>
        </div>

        {/* buttons */}
        <div className='w-full flex flex-col gap-5'>
          <Button
            loading={isLoading}
            onClick={handleCancelPlan}
            title='Yes, Cancel Application'
          />

          <Button onClick={closeModal} title='Cancel' theme='secondary' />
        </div>
      </div>
    </ModalBgLayout>
  );
};

export default CancelApplicationModal;
