import { CoverageDetail } from "components/coverages/coverage-card"

const generalLimits = <>
  {/* Limits */}
  <CoverageDetail
    title='Coverage Limit'
    limit={['$30,000', '6 months rent']}
  >
    The coverage limit is the maximum amount that can be paid for a covered cancellation. For example, if you have a limit of 6 months, the maximum amount that will be covered for a cancellation is 6 months of rent.
  </CoverageDetail>

  {/* Deductibles */}
  <CoverageDetail
    title='Deductible'
    limit={['$500', 'one months rent']}
  >
    A deductible is the amount you pay out-of-pocket for covered
    cancellations before your coverage starts to pay. For example, if you have a $500 deductible, you would pay the first $500 of covered expenses for a cancellation.
  </CoverageDetail>  
</>

export default generalLimits
