import { useDownloadPlanPdfMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import Button from 'common/components/button';

function View({ planId }) {
  const [downloadPdf, { isLoading: isDownloadPdfLoading }] =
    useDownloadPlanPdfMutation();

  return (
    <Button
      linkId='Download Plan Details'
      loading={isDownloadPdfLoading}
      disabled={isDownloadPdfLoading}
      theme='secondary'
      onClick={() => {
        downloadPdf(planId).then(() => {});
      }}
      title='Download Plan Details'
    />
  );
}

export default View;
