/* eslint-disable no-unused-vars */
import segmentPlugin from '@analytics/segment';
import Analytics from 'analytics';
import { userTrackingEnum } from '../enums';

function debugFn(...args) {
  if (process.env.REACT_APP_USER_TRACKING === userTrackingEnum.CONSOLE) {
    console.log(args);
  }
}

const stuff = segmentPlugin({
  writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
});

let analytics = {
  page: debugFn,
  track: debugFn,
  identify: debugFn,
};

if (process.env.REACT_APP_USER_TRACKING === userTrackingEnum.SEGMENT) {
  analytics = Analytics({
    app: 'cios-app',
    plugins: [
      segmentPlugin({
        writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
      }),
    ],
  });
}

export default analytics;
