const AnchorLinkTag = ({
  className = '',
  children,
  hrefPath = '',
  target = '_blank',
  rel = 'noopener noreferrer',
}) => {
  return (
    <a className={className} href={hrefPath} target={target} rel={rel}>
      {children}
    </a>
  );
};

export default AnchorLinkTag;
