import ModalBgLayout from '../../../layouts/modal-bg';
import Button from 'common/components/button';

const ConfirmActionModal = ({ onConfirmClick, onCancel }) => {
  return (
    <ModalBgLayout>
      <div className='flex flex-col w-full gap-10'>
        {/* icon and texts*/}
        <h3 className='text-center text-2xl font-bold text-[#191923]'>
          Are you sure?
        </h3>

        {/* buttons */}
        <div className='flex items-center w-full gap-5'>
          <Button onClick={onCancel} theme='secondary' title='Cancel' />
          <Button onClick={onConfirmClick} title='Yes' />
        </div>
      </div>
    </ModalBgLayout>
  );
};

export default ConfirmActionModal;
