import Select from 'react-select';
import React, { useCallback } from 'react';

const SelectInput = ({
  options,
  placeholder,
  name = '',
  onChange,
  value,
  onFocus,
  onBlur,
  disabled,
  defaultInputValue,
  defaultValue,
  isOptionDisabled,
  isOptionSelected,
  isSearchable = true,
  tracker,
}) => {
  const _onChange = useCallback((event) => {
    if (tracker) {
      tracker(name);
    }
    if (onChange) {
      onChange(event);
    }
  }, []);
  return (
    <Select
      isOptionSelected={isOptionSelected}
      isOptionDisabled={isOptionDisabled}
      isSearchable={isSearchable}
      isClearable={true}
      name={name}
      onBlur={onBlur}
      onChange={_onChange}
      isDisabled={disabled}
      onFocus={onFocus}
      value={value}
      defaultInputValue={defaultInputValue}
      defaultValue={defaultValue}
      theme={(theme) => ({
        ...theme,
        // borderRadius: 0,
        colors: {
          ...theme.colors,
          primary: '#191923',
          primary25: '#F1F5F9',
        },
      })}
      classNames={{
        control: (state) => `text-base flex items-center justify-between w-full border-1 rounded-md bg-white min-h-10 h-fit sm:h-10 gap-x-0 ${disabled ? 'bg-slate-100 opacity-60' : state.isFocused ? `border-black drop-shadow-none` : `border-slate-300`}`,
        indicatorSeparator: () => '!bg-transparent',
        valueContainer: () => '!pl-3 pr-0',
        option: (state) => `cursor-pointer ${state.isFocused ? `bg-slate-100 text-black font-semibold` : state.isSelected ? `bg-slate-100 text-black font-semibold` : `bg-white text-black`} ${state.isDisabled && 'cursor-not-allowed opacity-50'}`,
        menu: () => `bg-white rounded-md overflow-hidden`,
        menuList: () => `py-0 no-scrollbar text-base`,
      }}
      placeholder={placeholder}
      options={options}
    />
  );
};

export default SelectInput;
