/* eslint-disable no-unused-vars */
// provider.js
import { useState, useRef } from 'react';
import { userTrackingEnum } from '../enums';
import analytics from './analytics';
import UserTrackerContext from './context';

// Potentially need time for the value to propagate
// 40 ms is roughly 1 frame in 24 fps
const VALUE_PROPAGATION_TIME_MS = 40;

const isUserTrackingEnabled =
  process.env.REACT_APP_USER_TRACKING === userTrackingEnum.CONSOLE ||
  process.env.REACT_APP_USER_TRACKING === userTrackingEnum.SEGMENT;

const UserTrackerProvider = ({ children }) => {
  const [userData, setUserTracker] = useState({});
  const [pageId, setPageId] = useState('');
  // This has to be handled as completely mutable, and outside of react's normal state handling
  // let formData = {};
  let formData = useRef({});

  // By default don't do anything to the form data
  let formDataFormatter = () => {
    return formData.current;
  };
  let _formDataFormatter = useRef(formDataFormatter);

  const setFormData = (d) => {
    if (!isUserTrackingEnabled) {
      return;
    }
    // formData = d;
    formData.current = d;
  };

  // When the form has custom way it needs to format the form data for display purposes, can set this
  const setFormDataFormatter = (fn) => {
    if (!isUserTrackingEnabled) {
      return;
    }

    if (!fn) {
      _formDataFormatter.current = formDataFormatter;
      return;
    }

    _formDataFormatter.current = fn;
  };

  const updateUserTracker = (newData) => {
    if (!isUserTrackingEnabled) {
      return;
    }
    setUserTracker((prevData) => ({ ...prevData, ...newData }));
  };

  const trackPageLoad = (pageId) => {
    if (!isUserTrackingEnabled) {
      return;
    }
    setPageId(pageId);
    analytics.page({ title: pageId });
  };

  const trackIdentity = (...args) => {
    if (!isUserTrackingEnabled) {
      return;
    }
    analytics.identify(...args);
  };

  const trackNav = (args) => {
    if (!isUserTrackingEnabled) {
      return;
    }
    analytics.track('navigation', args);
  };

  const trackLink = (linkId) => {
    if (!isUserTrackingEnabled) {
      return;
    }
    analytics.track('link', {
      linkId: linkId,
      pageId,
      formData: _formDataFormatter.current(formData.current),
    });
  };

  // A field was change somehow, need to log it
  const trackFieldPopulate = (name) => {
    if (!isUserTrackingEnabled) {
      return;
    }
    // Doing this in a timeout to make sure the value was propagated
    // onChange doesn't necessarily change the value in a way that formik picks up quick enough
    setTimeout(() => {
      analytics.track('fieldPopulate', {
        name,
        formData: _formDataFormatter.current(formData.current),
      });
    }, VALUE_PROPAGATION_TIME_MS);
  };

  const trackFormSubmission = (data) => {
    if (!isUserTrackingEnabled) {
      return;
    }
    analytics.track('formSubmission', {
      formName: formData.current.formName || '',
      formData: _formDataFormatter.current(formData.current),
      serverData: data,
    });
  };

  const trackFormSubmissionSuccess = (data) => {
    if (!isUserTrackingEnabled) {
      return;
    }
    analytics.track('formSubmissionSuccess', {
      formName: formData.current.formName || '',
      formData: _formDataFormatter.current(formData.current),
      serverData: data,
    });
    // should reset the general form data
    setFormData({});
  };

  const trackFormSubmissionError = (data, error) => {
    if (!isUserTrackingEnabled) {
      return;
    }
    analytics.track('formSubmissionError', {
      formName: formData.current.formName || '',
      data,
      error,
    });
  };

  const trackFormLoad = () => {
    if (!isUserTrackingEnabled) {
      return;
    }

    analytics.track('formLoad', {
      formName: formData.current.formName || '',
      formData: _formDataFormatter.current(formData.current),
    });
  };

  const trackForm = (...args) => {
    if (!isUserTrackingEnabled) {
      return;
    }
    analytics.trackForm(...args);
  };

  const trackFieldBlur = (name, handleBlur) => {
    return (e) => {
      if (!isUserTrackingEnabled) {
        handleBlur(e);
        return;
      }
      trackFieldPopulate({ name, formData: formData.current });
      handleBlur(e);
    };
  };

  const trackFieldChange = (name, handleChange) => {
    return (e) => {
      if (!isUserTrackingEnabled) {
        handleChange(e);
        return;
      }
      trackFieldPopulate({ name, formData: formData.current });
      handleChange(e);
    };
  };

  const trackCustomEvent = (eventName, details) => {
    if (!isUserTrackingEnabled) {
      return;
    }
    analytics.track(eventName, details);
  };

  return (
    <UserTrackerContext.Provider
      value={{
        setFormData,
        setFormDataFormatter,
        updateUserTracker,
        trackPageLoad,
        trackFieldBlur,
        trackFieldPopulate,
        trackFieldChange,
        trackFormLoad,
        trackCustomEvent,
        trackFormSubmission,
        trackFormSubmissionError,
        trackFormSubmissionSuccess,
        trackForm,
        trackNav,
        trackLink,
        trackIdentity,
      }}
    >
      {children}
    </UserTrackerContext.Provider>
  );
};

export default UserTrackerProvider;
