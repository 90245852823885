import { useNavigate, useParams } from 'react-router-dom';
import { ProgressCard } from 'components/shared/progress-card';
import ApplyForClaimLayout from 'layouts/apply-for-claim';
import {
  FaCheck,
  FaCheckToSlot,
  FaClipboardList,
  FaPaperPlane,
} from 'react-icons/fa6';
import { FaInfoCircle } from 'react-icons/fa';
import {
  ApplyAside,
  ApplyContainer,
  ApplyHeader,
  ApplyMain,
} from 'components/shared/apply-form';
import { useCancelClaimMutation, useGetSingleClaimQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { claimTypeEnum, statusEnum } from 'utils/enums';
import DeleteModal from 'common/components/delete-modal';
import { toast } from 'react-toastify';
import { useState } from 'react';

const ApplyForClaim = () => {
  const { claimId } = useParams();
  const [ cancelClaim, { isLoading: cancelClaimLoading } ] = useCancelClaimMutation();
  const [cancelModal, setCancelModal ] = useState({ open: false, claimId: null });

  // HOOKS
  const navigate = useNavigate();
  const { data: claimData, isLoading: getSingleClaimLoading } =
    useGetSingleClaimQuery(claimId, { skip: false });
  
  // HANDLERS
  const handleGoToDashboard = () => {
    navigate('/dashboard/claims');
  };

  console.log('claimData', claimData);

  return (
    <>
      {cancelModal.open && (
        <DeleteModal
          handleDelete={() => {
            cancelClaim({ claimId: cancelModal.claimId })
              .unwrap()
              .then(() => {
                setCancelModal({ open: false, claimId: null });
                navigate('/dashboard/claims');
              })
              .catch((error) => {
                toast.error(error?.data?.msg || 'Error cancelling claim');
              });
          }}
          onCancel={() => setCancelModal({ open: false, claimId: null })}
          isLoading={cancelClaimLoading}
        />
      )}

      <ApplyContainer>
        <ApplyHeader onClick={handleGoToDashboard} />

        <ApplyMain>
          <ApplyAside
            // Save is always available, but text changes depending on whether the claim has been created
            save={handleGoToDashboard}
            saveText={claimId ? 'Save & Continue Later' : 'Go to Home' }
            // No tenant endpoint for deleting claims yet
            // Can implement in the future if there is sufficient demand
            cancel={() => setCancelModal({ open: true, claimId })}
            cancelCondition={claimId && claimData?.status === statusEnum.Draft}
            cancelText='Cancel Claim Submission'
          >
            {/* Never blurred */}
            {/* Complete when claim is created */}
            <ProgressCard
              CardIcon={FaCheck}
              title='Select Plan'
              step={1}
              isBlurred={false}
              isCompleted={claimId && claimData}
            />
            {/* Blurred when claim is not created */}
            {/* Complete when claim has type */}
            <ProgressCard
              CardIcon={FaClipboardList}
              title='Claim Type'
              step={2}
              isBlurred={!claimId}
              isCompleted={claimId && claimData?.claimType !== 'none'}
            />
            {/* Blurred when claim submission is not set */}
            {/* Complete when claim submission is set */}
            <ProgressCard
              CardIcon={FaInfoCircle}
              title='Claim Details'
              step={3}
              isBlurred={claimData?.claimType !== claimTypeEnum.cancelAnytime && claimData?.claimType !== claimTypeEnum.leaveAnytime}
              isCompleted={claimId && claimData?.submission.moveOutEnd}
            />
          </ApplyAside>

          {/* main section */}
          <ApplyForClaimLayout claimId={claimId} claimData={claimData}/>
        </ApplyMain>
      </ApplyContainer>
    </>
  );
};

export default ApplyForClaim;
