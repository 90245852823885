import { ThreeDots } from "react-loader-spinner";
import { iconPositionEnum } from "common/utils/enums";
import useUserTracker from "common/utils/user-tracker/useUserTracker";

// Added a control to toggle between w-full and w-auto
// Added a control for horizontal padding

const Button = ({
  style,
  Icon,
  linkId,
  theme = "primary",
  title = "Add title",
  type = "button",
  loading = false,
  disabled = false,
  onClick,
  role,
  wFull = true,
  padding = "px-4",
  iconPosition = iconPositionEnum.RIGHT,
  className,
  fixedHeight = true,
  id,
}) => {
  // DATA INITAILIZATION
  const borderColor =
    theme === "primary"
      ? "border-[#191923]"
      : theme === "secondary"
        ? "border-[#CED0CE]"
        : "";
  const bgColor =
    theme === "primary"
      ? "bg-[#191923]"
      : theme === "secondary"
        ? "bg-white"
        : "";
  const textColor =
    theme === "primary"
      ? "text-white"
      : theme === "secondary"
        ? "text-[#191923]"
        : "";
  const hover =
    theme === "primary"
      ? "hover:opacity-80"
      : theme === "secondary"
        ? "hover:bg-gray-200"
        : "";
  const { trackLink } = useUserTracker();
  const _onClick = (e) => {
    if (linkId) {
      trackLink(title || linkId);
    }
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button
      style={style}
      role={role}
      type={type}
      onClick={_onClick}
      className={`rounded-full border ${borderColor} ${textColor} ${bgColor} ${hover} flex justify-center gap-x-4 px-6 py-3 max-h-12 items-center ${
        wFull ? "w-full" : "w-auto"
      } text-base font-semibold disabled:opacity-40 disabled:cursor-not-allowed transition-all ${padding} ${className}`}
      disabled={disabled}
      id={id}
    >
      {loading ? (
        <span className="inline-flex mx-auto items-center justify-center">
          <ThreeDots
            height="40"
            width="40"
            radius="9"
            color={textColor}
            ariaLabel="three-dots-loading"
            visible={true}
          />
        </span>
      ) : (
        <span
          className={`inline-flex items-center gap-3 ${
            iconPosition === iconPositionEnum.LEFT
              ? "flex-row-reverse"
              : "flex-row"
          }`}
        >
          <span className="whitespace-nowrap">{title}</span>

          {/* icon */}
          {Icon ? <Icon className={`text-xl ${textColor}`} /> : null}
        </span>
      )}
    </button>
  );
};

export default Button;
