import NavBar from 'components/shared/navbar';
import { useEffect, useState } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { IoIosSend } from 'react-icons/io';
import { IoSettingsSharp } from 'react-icons/io5';
import { PiWarningCircleFill } from 'react-icons/pi';
import { RiComputerLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useRetrieveUserDetailsQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { clearClaimsDraft } from 'redux/features/claims/claimsSlice';
import { clearPlansDraft } from 'redux/features/plans/plansSlice';
import { extractInitials } from 'common/utils/extractInitials';
import { isEmpty } from 'common/utils/isEmpty';
import ActiveLink from 'components/shared/active-link';
import ConfirmActionModal from 'components/shared/confirm-action-modal';
import LogoImage from 'common/components/logo-image';
import { logout, setUserDetails } from 'redux/features/auth/authSlice';
import ModalLayout from 'layouts/modal';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { FaRightFromBracket } from 'react-icons/fa6';
import { Icon } from '@chakra-ui/react';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import Button from 'common/components/button';
import { FaHome } from 'react-icons/fa';

const DashboardMainLayout = ({ children, title = 'Title' }) => {
  // STATES
  const [confirmModalState, setConfirmModalState] = useState(false);

  // HOOKS
  const { trackLink } = useUserTracker();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  // HANDLERS
  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearClaimsDraft());
    dispatch(clearPlansDraft());
    navigate('/login');
  };

  // SIDE EFFECTS
  useEffect(() => {
    if (isRetrieveUserDetailsSuccess && !isEmpty(userDetails)) {
      dispatch(setUserDetails(userDetails));
    }
  }, []);

  return (
    <main className='h-screen mobile:h-full flex mobile:flex-col'>
      {/* aside */}
      <aside className='h-full w-full max-w-xs flex flex-col relative gap-8 border-r border-gray-200 px-5 py-8 mobile:hidden'>
        {/* icon */}
        <LogoImage className='ml-6 mobile:ml-3' small={true} />

        {/* link buttons */}
        <div className='flex flex-col gap-3'>
          <ActiveLink
            Icon={FaHome}
            linkId='Home'
            to={'/dashboard/home'}
            text='Home'
          />
          <ActiveLink
            linkId='Plans'
            Icon={IoIosSend}
            to={'/dashboard/plans'}
            text='Plans'
          />
          <ActiveLink
            Icon={PiWarningCircleFill}
            linkId='Claims'
            to={'/dashboard/claims'}
            text='Claims'
          />
          <ActiveLink
            linkId='Settings'
            Icon={IoSettingsSharp}
            to={'/dashboard/settings'}
            text='Settings'
          />
          <AnchorLinkTag
            className='w-full'
            hrefPath='mailto:members@releaserent.com'
          >
            <Button theme='secondary' title='Chat with an Expert' />
          </AnchorLinkTag>
        </div>

        {/* profile details and logout */}
        <div className='flex flex-col w-full mt-auto gap-3'>

          {/* profile details */}
          <div className='cursor-pointer hover:bg-gray-100 border-y border-[#E5E7EB] text-[#191923] text-base font-medium py-4 pl-3 grid grid-cols-[40px_auto] gap-3'>
            {/* profile image */}
            <span className='inline-flex bg-black h-10 w-10 rounded-[50%] items-center justify-center text-white font-bold text-sm uppercase'>
              {extractInitials(userDetails?.name || `${userDetails?.firstName} ${userDetails?.lastName}`)}
            </span>

            {/* name and email address */}
            <div className='flex flex-col w-full gap-1 overflow-hidden truncate ...'>
              <span className='text-base font-medium text-[#191923]'>
                {userDetails?.name || `${userDetails?.firstName} ${userDetails?.lastName}`}
              </span>
              <span className='text-[#CED0CE] font-normal text-sm truncate ...'>
                {userDetails?.email}
              </span>
            </div>
          </div>

          {/* Logout */}
          <div
            onClick={() => {
              trackLink('Logout');
              setConfirmModalState(true);
            }}
            className='flex items-center gap-4 transition-all rounded-[8px] px-6 py-4 hover:no-underline hover:bg-[#bbbbbb4e] cursor-pointer text-[#6A6C70]'
          >
            <Icon as={FaRightFromBracket} className='text-2xl' />
            <span className='text-base font-medium'>Logout</span>
          </div>
        </div>
      </aside>

      {/* mobile: hamburger menu */}
      <NavBar />

      {/* main section */}
      <section className='w-full relative px-8 py-8 flex flex-col overflow-auto gap-8 mobile:px-6 mobile:gap-5 mobile:relative mobile:z-[100]'>
        {/* heading */}
        <div className='pb-5 flex items-center w-full border-b border-[#E5E7EB] gap-4'>
          <p className='text-4xl text-[#191923] font-bold mobile:text-3xl'>
            {title}
          </p>
        </div>

        {/* Content */}
        {children}
      </section>

      {/* MODAL */}
      <ModalLayout modalIsOpen={confirmModalState}>
        <ConfirmActionModal
          onConfirmClick={handleLogout}
          onCancel={() => setConfirmModalState(false)}
        />
      </ModalLayout>
    </main>
  );
};

export default DashboardMainLayout;
