const AuthLabel = ({ title = 'Add title', isActive = true }) => {
  // DATA INTIALIZATION
  const bgColor = isActive ? 'bg-[#191923]' : 'bg-white';
  const textColor = isActive ? 'text-white' : 'text-[#191923]';
  return (
    <span
      className={`${bgColor} ${textColor} h-[50px] py-4 rounded-[61px] px-7 mobile:px-10 inline-flex items-center font-semibold`}
    >
      {title}
    </span>
  );
};

export default AuthLabel;
