import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { usePagination } from '../../../hooks/usePagination';
import { DOTS } from '../../../utils/enums';

const Pagination = ({
  setCurrentPage,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize = 10,
}) => {
  // HOOKS
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // HANDLERS
  const onNext = () => {
    setCurrentPage(currentPage + 1);
  };
  const onPrevious = () => {
    setCurrentPage(currentPage - 1);
  };

  // DATA INITIALIZATION
  const lastPage = paginationRange[paginationRange.length - 1];

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  return (
    <div className='w-full flex items-center justify-between gap-2 py-5 border-t border-[#E1E6EF]'>
      {/* back button */}
      <button
        disabled={currentPage <= 1}
        onClick={onPrevious}
        className='inline-flex gap-3 items-center text-sm font-medium text-[#6A6C70] [outline:none] disabled:opacity-40 disabled:cursor-not-allowed'
      >
        <BsArrowLeft className='text-lg font-medium text-[#6A6C70]' />
        Previous
      </button>

      {/* pagination items */}
      <ul className='flex items-center gap-3 mobile:hidden'>
        {paginationRange?.map((pageNumber, index) => {
          // If the pageItem is a DOT, render the DOTS unicode character
          if (pageNumber === DOTS) {
            return (
              <li
                key={index}
                className='inline-flex items-center justify-center w-10 h-10 text-sm font-medium rounded-[50%] text-[#6A6C70]'
              >
                &#8230;
              </li>
            );
          }

          // Render the pagination items
          return (
            <li
              onClick={() => setCurrentPage(pageNumber)}
              key={index}
              className={`inline-flex items-center justify-center w-10 h-10 text-sm font-medium rounded-[50%] cursor-pointer ${
                currentPage === pageNumber
                  ? 'border border-[#191923] bg-[#191923] text-white'
                  : 'text-[#6A6C70]'
              }`}
            >
              {pageNumber}
            </li>
          );
        })}
      </ul>

      {/* mobile: current page indicator */}
      <span className='w-10 h-10 text-sm font-medium rounded-[50%] cursor-pointer bg-[#191923] border-[#191923] text-white border hidden mobile:inline-flex justify-center items-center'>
        {currentPage}
      </span>

      {/* next button */}
      <button
        disabled={currentPage >= lastPage}
        onClick={onNext}
        className='inline-flex gap-3 items-center text-sm font-medium text-[#6A6C70] [outline:none] disabled:opacity-40 disabled:cursor-not-allowed'
      >
        Next
        <BsArrowRight className='text-lg font-medium text-[#6A6C70]' />
      </button>
    </div>
  );
};

export default Pagination;
