import { useLocation } from 'react-router-dom';
import { NavLink } from 'common/utils/user-tracker/react-router-dom';

const ActiveLink = ({
  to,
  Icon,
  linkId,
  text = 'Insert Link',
  className = '',
}) => {
  // DATA INITIALIZATION
  const location = useLocation();
  const isActive = location.pathname.includes(to);
  return (
    <NavLink
      end
      linkId={linkId}
      to={to}
      className={`flex py-4 items-center gap-4 transition-all rounded-[8px] px-6 hover:no-underline ${className}
        ${isActive ? 'hover:opacity-70 bg-[#191923]' : 'hover:bg-[#bbbbbb4e]'}
      `}
    >
      {/* icon */}
      {isActive ? (
        <Icon className='text-white text-2xl' />
      ) : (
        <Icon className='text-[#6A6C70] text-2xl' />
      )}

      {/* text */}
      {isActive ? (
        <span className='font-medium text-base text-[#FBFEF9]'>{text}</span>
      ) : (
        <span className='font-medium text-base text-[#6A6C70]'>{text}</span>
      )}
    </NavLink>
  );
};

export default ActiveLink;
