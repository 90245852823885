import Button from 'common/components/button';
import BgLayout from 'common/components/bg';
import { FaCircleCheck } from 'react-icons/fa6';
import { Link } from 'common/utils/user-tracker/react-router-dom';
import Tracker from 'components/auth/reset-password-success/tracker';

function View() {
  return (
    <>
      <BgLayout>
        {/* texts and icon */}
        <div className='flex flex-col text-center items-center gap-6'>
          <FaCircleCheck className='text-[#34D399] text-[52px]' />

          <h2 className='font-bold text-4xl text-[#191923] mobile:text-2xl'>
            Password Changed
          </h2>

          <h4 className='text-[#191923] text-lg w-4/5 font-normal'>
            You have successfully created a new password proceed to the
            login page.
          </h4>
        </div>

        {/* button */}
        <Link to='/login' linkId='Proceed to login' className='w-full'>
          <Button type='button' title='Proceed to login' />
        </Link>
      </BgLayout>
      <Tracker />
    </>
  );
}

export default View;
