import { FaCheck, FaRegUser } from 'react-icons/fa';
import { Icon } from '@chakra-ui/react';
import { FaUser } from 'react-icons/fa6';

// Blurred - Future steps not yet available to member
// Completed - Step is already completed by member

const ProgressIcon = ({ isBlurred, isCompleted, CardIcon, zIndex, className }) => {
  return (
    <span
      className={`items-center h-10 w-10 flex justify-center border border-black rounded-full ${zIndex} ${isCompleted ? 'bg-black text-white' : 'bg-slate-100'} ${isBlurred && 'border-slate-200'} ${className}`}
    >
      <Icon
        className={`text-lg ${isBlurred && 'text-slate-400'}`}
        as={isCompleted ? FaCheck : CardIcon || FaUser}
        boxSize={4}
      />
    </span>
  ) 
}

const Card = ({ isBlurred, isCompleted, title, CardIcon, step, zIndex, className }) => {
  return ( 
    <div
      className={`w-full rounded-lg border border-black px-4 flex items-center py-4 gap-4 relative ${isBlurred && 'opacity-25 border-slate-400'} ${className}`}
    >
      {/* CardIcon */}
      <ProgressIcon isBlurred={isBlurred} isCompleted={isCompleted} CardIcon={CardIcon} zIndex={zIndex} />

      {/* text */}
      <div className='flex flex-col'>
        <p className='text-sm font-semibold uppercase tracking-wider text-slate-500'>Step {step}</p>
        <p className='text-lg font-bold'>{title}</p>
      </div>
    </div>
  )
}

const ProgressCard = ({
  step,
  isBlurred,
  isCompleted,
  title,
  CardIcon,
  zIndex = 'z-50',
}) => {
  return (
    <>
      {/* Desktop Version */}
      <Card isBlurred={isBlurred} isCompleted={isCompleted} title={title} CardIcon={CardIcon} step={step} zIndex={zIndex} className='mobile:hidden' />

      {/* Mobile Progress Bar */}
      <div className={`hidden mobile:flex w-full h-1 ${isCompleted ? 'bg-slate-400' : isBlurred ? 'bg-slate-200' : 'bg-black'}`} />
    </>
  );
};

export { ProgressCard, ProgressIcon };
