export const relationshipStatusOptions = [
  {
    label: 'Single',
    value: 'single',
  },
  {
    label: 'Married',
    value: 'married',
  },
  {
    label: 'Common law',
    value: 'commonLaw',
  },
  {
    label: 'Non-common law cohabitating relationship',
    value: 'nonCommonCohab',
  },
];
export const residencyStatusOptions = [
  {
    label: 'Citizen or Permanent Resident',
    examples: ['EB-1', 'EB-2', 'EB-3'],
    value: 'citizenPermanent',
  },
  {
    label: 'Employer-Sponsored Work Visa',
    examples: ['H-1B', 'L-1', 'O-1'],
    value: 'employerVisa',
  },
  {
    label: 'Other Visa Work',
    examples: ['H-4 EAD', 'L-2 EAD', 'E-2/E-1 EAD'],
    value: 'otherVisa',
  },
  {
    label: 'Student Visa',
    examples: ['F-1', 'M-1', 'J-1'],
    value: 'studentVisa',
  },
  {
    label: 'Other Temporary Visa',
    examples: ['B-1/B-2', 'E-2/E-3', 'H-2A/B', 'J-1', 'P-1'],
    value: 'otherTemporaryVisa',
  },
];
export const highestEducationLevelOptions = [
  {
    label: 'Select education level',
    value: '',
  },
  {
    label: 'High School Diploma',
    value: 'highSchoolGed',
  },
  {
    label: 'Community College or Trade School Certification',
    value: 'communityTrade',
  },
  {
    label: 'Associate Degree',
    value: 'associate',
  },
  {
    label: 'Bachelors Degree',
    value: 'bachelor',
  },
  {
    label: 'Masters',
    value: 'master',
  },
  {
    label: 'PhD',
    value: 'phd',
  },
  {
    label: 'No degree',
    value: 'none',
  },
];
export const planCurrencyOptions = [
  {
    label: 'US Dollars',
    value: 'USD',
  },
  {
    label: 'Euros',
    value: 'EUR',
  },
  {
    label: 'Canadian Dollars',
    value: 'CAD',
  },
];
