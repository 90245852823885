import {
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/features/auth/authSlice';
import Button from 'common/components/button';
import { toast } from 'react-toastify';

const CheckoutForm = ({ returnUrl, onClick }) => {
  // STATES
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // DATA INTIALIZATION
  const stripe = useStripe();
  const elements = useElements();
  const userDetails = useSelector(selectUser);
  const options = {
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: false
    }
  };

  // HANDLERS
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);      
    } else {
      setMessage('An unexpected error occured.');
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (message) {
      toast.error(message);
    }
  }, [message]);

  return (
    <div className='w-full h-full'>
      <form
        className='w-full flex flex-col gap-4'
        id='payment-form'
      >
        <LinkAuthenticationElement
          id='link-authentication-element'
          options={{
            defaultValues: {
              email: userDetails?.email || 'hello@releaserent.com',
            },
          }}
        />
        <PaymentElement 
          id='payment-element' 
          options={options}
        />
        <Button
          disabled={isLoading || !stripe || !elements}
          id='submit'
          title='Complete Payment'
          onClick={handleSubmit}
          className='mt-4'
        />
        {onClick && <Button 
          onClick={onClick}
          theme='secondary'
          title='Choose a Different Plan'
        />}
      </form>
    </div>
  );
};

export default CheckoutForm;
