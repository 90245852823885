import { settingsTab } from '../../../utils/enums';

const Tabs = ({ currentTab, setCurrentTab }) => {
  return (
    <div className='border-y-[1.5px] border-[#E5E7EB] py-5 flex items-center gap-20 mobile:gap-12'>
      <span
        onClick={() => setCurrentTab(settingsTab.ACCOUNT)}
        className={`text-base text-[#191923] cursor-pointer ${
          currentTab === settingsTab.ACCOUNT ? 'font-bold' : 'font-normal'
        }`}
      >
        Account
      </span>
      {/* <span
        onClick={() => setCurrentTab(settingsTab.VERIFICATION)}
        className={`text-base text-[#191923] cursor-pointer ${
          currentTab === settingsTab.VERIFICATION ? 'font-bold' : 'font-normal'
        }`}
      >
        Verification
      </span> */}
    </div>
  );
};

export default Tabs;
