import ActivePlanReg from 'components/apply-for-plan/active-plan-reg';
import PendingPlanReg from 'components/plans/pending-plan-reg';
import StatusLabel from 'components/shared/status-label';
import { Link } from 'react-router-dom';
import { statusEnum, stripeKYCEnum } from 'utils/enums';
import Button from 'common/components/button';

const VerifyIdentity = ({ verificationStatus }) => {
  return (
    <div className='w-1/2 flex flex-col gap-8 mobile:w-full'>
      {/* label */}
      {verificationStatus === stripeKYCEnum.pending ? (
        <PendingPlanReg status={statusEnum.inReview} />
      ) : verificationStatus === stripeKYCEnum.failed ? (
        <div className='w-full flex flex-col gap-10 text-center'>
          {/* first */}
          <div className='flex flex-col gap-6 items-center w-full'>
            {/* label */}
            <div className='flex flex-col gap-4 items-center'>
              <span className='text-[#191923] text-base font-normal'>
                Status:
              </span>

              <StatusLabel status={statusEnum.cancelled} size='base' />
            </div>

            {/* title */}
            <p className='text-3xl w-[70%] font-bold text-[#191923]'>
              Payment Failed
            </p>

            {/* subtitle */}
            <p className='w-[70%] text-xl text-[#191923] mobile:w-full'>
              Your personalized coverage plan payment was not successful
            </p>
          </div>

          {/* button */}
          <Link to='/dashboard/plans?status=active' className='w-full'>
            <Button title='Ok, Return to Home' />
          </Link>
        </div>
      ) : verificationStatus === stripeKYCEnum.verified ? (
        <ActivePlanReg />
      ) : null}
    </div>
  );
};

export default VerifyIdentity;
