import { useState, useCallback } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FiLock } from 'react-icons/fi';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';

const PasswordInput = ({
  placeholderText = '',
  name = '',
  onChange,
  value,
  onFocus,
  onBlur,
  disabled,
}) => {
  // STATES
  const [isHidden, setIsHidden] = useState(true);
  const { trackFieldPopulate } = useUserTracker();
  // These values are filtered, only says that the field was changed
  const _onBlur = useCallback((event) => {
    if (onBlur) {
      onBlur(event);
    }
    trackFieldPopulate(name);
  }, []);
  return (
    <div className={`flex items-center justify-between w-full border-solid border border-[#CED0CE] rounded-md bg-white h-10 px-3 gap-x-3 ${disabled && 'bg-slate-100 opacity-60'}`}>
      {/* icon */}
      <FiLock className='text-lg text-[#6A6C70]' />

      {/* input */}
      <input
        placeholder={placeholderText}
        name={name}
        value={value}
        type={isHidden ? 'password' : 'text'}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={_onBlur}
        disabled={disabled}
        className='h-full w-full border-none [outline:none] text-base text-[#191923] font-normal placeholder:text-[#6A6C70] placeholder:font-normal placeholder:text-base disabled:bg-inherit disabled:opacity-60'
      />

      {/* password ishidden indicator */}
      {isHidden ? (
        <AiOutlineEyeInvisible
          onClick={() => setIsHidden(!isHidden)}
          className='text-lg text-[#6A6C70] cursor-pointer'
        />
      ) : (
        <AiOutlineEye
          onClick={() => setIsHidden(!isHidden)}
          className='text-lg text-[#6A6C70] cursor-pointer'
        />
      )}
    </div>
  );
};

export default PasswordInput;
