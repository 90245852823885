import { useState } from 'react';
import { TiHome } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import AccountInformationSettings from 'components/settings/account-information-settings';
import Tabs from 'components/settings/tabs';
import VerificationSettings from 'components/settings/verification-settings';
import DashboardMainLayout from 'layouts/dashboard-main';
import { settingsTab } from 'utils/enums';
import { Icon } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa6';

const Settings = () => {
  // STATES
  const [currentTab, setCurrentTab] = useState(() => settingsTab.ACCOUNT);
  return (
    <DashboardMainLayout title='Settings'>
      <div className='flex flex-col w-full gap-8'>
        {/* breadcrumb */}
        <div className='flex gap-x-3  gap-y-1 items-center flex-wrap'>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE] mobile:hidden'
          >
            Home
          </Link>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE] hidden mobile:inline'
          >
            <TiHome className='text-2xl' />
          </Link>
          <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
          <span className='text-[#191923] text-base font-normal'>Settings</span>
        </div>

        {/* second container */}
        <div className='w-full flex flex-col gap-3'>

          {/* Temporary hiding of the tab selector while we only have one tab (verification coming soon) */}
          <div className='hidden'>
            {/* Tab */}
            <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
          </div>
          
          {/* Content */}
          <section className='border-[1.5px] border-[#E5E7EB] rounded-[10px]'>
            {currentTab === settingsTab.ACCOUNT ? (
              <AccountInformationSettings />
            ) : currentTab === settingsTab.VERIFICATION ? (
              <VerificationSettings />
            ) : null}
          </section>
        </div>
      </div>
    </DashboardMainLayout>
  );
};

export default Settings;
