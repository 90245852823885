import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  token: '',
  user: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, { payload }) => {
      return {
        isLoggedIn: payload.isLoggedIn,
        token: payload.token,
        user: payload.user,
      };
    },
    setUserDetails: (state, { payload }) => {
      return {
        ...state,
        user: payload,
      };
    },
    logout: () => initialState,
  },
});

// ACTIONS
export const { setCredentials, logout, setUserDetails } = authSlice.actions;

// SELECTORS
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectToken = (state) => state.auth.token;
export const selectUser = (state) => state.auth.user;
export const selectUserEmailConfirmed = (state) =>
  state.auth.user?.emailConfirmed;
export const selectUserStatus = (state) => state.auth.user?.status;
export const selectUserStripeKYC = (state) => state.auth.user?.stripeKyc;

// REDUCERS
export default authSlice.reducer;
