import './index.css';

const HamburgerMenu = ({ isMenuOpen, onClick }) => {
  return (
    <div
      className={`menu-toggle hidden mobile:flex ${
        isMenuOpen ? 'bg-transparent' : 'bg-primary-light'
      } h-10 w-12 items-center justify-center rounded-md transition-all`}
    >
      <input defaultChecked={isMenuOpen} onClick={onClick} type='checkbox' />
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default HamburgerMenu;
