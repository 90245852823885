import AnchorLinkTag from 'components/shared/anchor-link-tag';
import { BsArrowRightShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Button from 'common/components/button';
import BgLayout from 'common/components/bg';
import Tracker from '../../components/auth/welcome/tracker';

const Welcome = () => {
  return (
    <>
      <BgLayout>
        {/* texts */}
        <div className='flex flex-col text-center gap-8'>
          <h2 className='font-bold text-4xl text-[#191923]'>
            Welcome to ReLease
          </h2>

          <h4 className='text-[#191923] text-base font-normal'>
            Our flexible leasing platform gives you the freedom to move on your own terms. Sign your lease with the confidence of knowing you can move or cancel anytime, for any reason.
          </h4>
        </div>

        {/* button */}
        <div className='flex w-full flex-col gap-7'>
          <Link to='/dashboard/apply-for-plans' className='w-full'>
            <Button title='Apply for a plan' linkId='applyForPlanBtn' />
          </Link>

          <AnchorLinkTag
            className='w-full'
            hrefPath='mailto:members@releaserent.com'
          >
            <Button
              theme='secondary'
              linkId='speakWithExpertBtn'
              Icon={BsArrowRightShort}
              title='Speak with an Expert '
            />
          </AnchorLinkTag>
        </div>
      </BgLayout>
      <Tracker />
    </>
  );
};

export default Welcome;
