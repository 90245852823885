import useTracker from 'common/utils/user-tracker/useTracker';

import mergeFormikState from 'common/utils/user-tracker/formik-merge';

function formDataFormatter({ values, errors, touched }) {
  let data = mergeFormikState({ values, errors, touched });
  if (data?.password?.value && data?.password?.value?.length > 0) {
    data.password.value = '*'.repeat(data.password.value.length);
  } else {
    data.password.value = '';
  }
  return data;
}

function View(formData) {
  useTracker({ formData, pageId: 'login', formDataFormatter });
}

export default View;
