import AuthAsideLayout from '../auth-aside';

const AuthMainLayout = ({ children }) => {
  return (
    <main className='h-screen w-screen flex mobile:flex-col mobile:min-h-screen'>
      {/* aside */}
      <AuthAsideLayout />

      {/* main section */}
      <section className='min-h-full w-[70%] mobile:w-full relative py-[5%] flex flex-col items-center overflow-auto mobile:h-full'>
        {children}
      </section>
    </main>
  );
};

export default AuthMainLayout;
