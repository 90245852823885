import LogoImage from 'common/components/logo-image';

const AuthAsideLayout = () => {
  return (
    <aside className='h-full w-[30%] flex justify-center relative pt-[5%] mobile:hidden'>
      {/* mesh bg */}
      <img
        src='/images/login/side-mesh.svg'
        alt='aside mesh bg'
        className='absolute top-0 left-0 w-full h-full z-10 object-cover'
      />

      {/* wrapper */}
      <div className='flex flex-col gap-16 relative z-20 w-[80%]'>
        {/* Show secondary logo on desktop, primary logo on mobile */}
        <LogoImage dark={true} className='hidden md:block'/>
        <LogoImage dark={false} className='mobile:hidden'/>

        {/* texts */}
        <div className='flex flex-col gap-7'>
          <h1 className='font-bold text-4xl text-[#FBFEF9]'>
            Flexibility to move anytime, for any reason
          </h1>

          <h2 className='font-bold text-2xl text-[#FBFEF9]'>
            Get covered starting at just $25/month
          </h2>

          <p className='text-[#FBFEF9] text-base font-normal'>
            Our flexible leasing platform gives you the freedom to move on your own terms. Sign your lease with the confidence of knowing you can move or cancel anytime, for any reason.
          </p>
        </div>
      </div>
    </aside>
  );
};

export default AuthAsideLayout;
