export async function convertFileToBlob(file) {
  if (!file) {
    throw new Error('File is not valid');
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Define what happens on successful file read
    reader.onload = function (loadEvent) {
      const arrayBuffer = loadEvent.target.result;

      const blob = new Blob([arrayBuffer]);

      resolve(blob);
    };

    // Define what happens on a read error
    reader.onerror = function (event) {
      reject(new Error('Error reading file: ' + event.target.error));
    };

    // Read the file as binary data
    reader.readAsArrayBuffer(file);
  });
}
