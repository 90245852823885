export function toDatePickerFormat(date) {
  if (!date) {
    return '';
  }
  if (typeof date === 'string') {
    try {
      return date.split('T')[0];
    } catch (e) {
      return '';
    }
  } else {
    const formatter = new Intl.DateTimeFormat('en-CA', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    return formatter.format(date);
  }
  console.log(date);
}