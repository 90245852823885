import { Link } from 'common/utils/user-tracker/react-router-dom';
import Button from 'common/components/button';

// This component is specifically for the dashboard messages that we're implementing for activations
// They contain an icon, title, description, and a button that links to an action

const DashboardMessage = ({ Icon, path='', title='', description=''}) => {
  return (
    <Link linkId={title} className='w-full' to={path}>
      <div className='flex border border-[#6A6C70] rounded-[10px] px-5 py-7 gap-5'>
        <div className='flex'>
          <Icon className='text-[#191923] mobile:text-[#191923] text-4xl' />
        </div>
        <div className='flex flex-col gap-2 items-start'>
          <span className='text-2xl text-[#191923] font-bold'>
            {title}
          </span>
          <p className='mb-3'>
            {description}
          </p>
          <Button wFull={false} padding='px-8' title={title}/>
        </div>
      </div>
    </Link>
  );
};

export default DashboardMessage;
