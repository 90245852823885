import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from 'App';
import CheckoutForm from 'components/stripe/checkout-form';

const Payment = ({ clientSecret, returnUrl, isPaymentIntent, onClick }) => {
  return (
    <Elements options={{ clientSecret }} stripe={stripePromise}>
      <CheckoutForm onClick={onClick} returnUrl={returnUrl} isPaymentIntent={isPaymentIntent} />
    </Elements>
  );
};

export default Payment;
