import ConfirmAccountSuccess from 'pages/auth/ConfirmAccountSuccess';
import ConfirmAccount from 'pages/auth/ConfirmAccount';
import RequestResetPassword from 'pages/auth/RequestResetPassword';
import ResetPasswordSuccess from 'pages/auth/ResetPasswordSuccess';
import ResetPassword from 'pages/auth/ResetPassword';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import RouteGuard from 'components/shared/route-guard';
import PageNotFound from 'pages/PageNotFound';
import ConfirmEmail from 'pages/auth/ConfirmEmail';
import LandingPage from 'pages/auth/LandingPage';
import Login from 'pages/auth/Login';
import LoginWithToken from 'pages/auth/LoginWithToken';
import SignUp from 'pages/auth/SignUp';
import Welcome from 'pages/auth/Welcome';
import ApplyForClaim from 'pages/claim/ApplyForClaim';
import ClaimDetails from 'pages/dashboard/claims/ClaimDetails';
import Claims from 'pages/dashboard/claims/Claims';
import DashboardHome from 'pages/dashboard/home/DashboardHome';
import PlanDetails from 'pages/dashboard/plans/PlanDetails';
import Plans from 'pages/dashboard/plans/Plans';
import Settings from 'pages/dashboard/settings/Settings';
import ApplyForPlan from 'pages/plan/ApplyForPlan';
import PlanPdf from 'pages/plan/pdf';
import NavigationTracker from 'common/utils/user-tracker/navigation';
import ReviewClaim from 'pages/claim/ReviewClaim';

const AppRouter = () => {
  return (
    <Router>
      <NavigationTracker />
      <Routes>
        {/* NOT FOUND/ INVALID ROUTE */}
        <Route path='*' element={<PageNotFound />} />

        {/* Temporary routes that will probably get moved */}
        <Route path='/dashboard/plans/:planId/pdf' element={<PlanPdf />} />

        {/* This is how an admin will impersonate a user */}
        <Route path='/use-login-token' element={<LoginWithToken />} />

        {/* ROUTE GUARD */}
        <Route element={<RouteGuard />}>
          {/* AUTH */}
          <Route path='/' element={<LandingPage />} />
          <Route path='/landing-page' element={<LandingPage />} />
          <Route path='/login' element={<Login />} />
          <Route
            path='/reset-password-success'
            element={<ResetPasswordSuccess />}
          />
          <Route
            path='/reset-password-request'
            element={<RequestResetPassword />}
          />
          <Route path='reset-email' element={<ResetPassword />} />
          <Route path='/signup' element={<SignUp />} />
          <Route
            path='/account-created-success'
            element={<ConfirmAccountSuccess />}
          />
          <Route path='/confirm-account' element={<ConfirmAccount />} />
          <Route path='/confirm-email' element={<ConfirmEmail />} />

          {/* DASHBOARD WELCOME */}
          <Route path='/dashboard-welcome' element={<Welcome />} />

          {/* DASHBOARD HOME */}
          <Route path='/dashboard/home' element={<DashboardHome />} />

          {/* DASHBOARD CLAIMS */}
          <Route
            path='/dashboard/apply-for-claims/:claimId'
            element={<ApplyForClaim />}
          />
          <Route
            path='/dashboard/apply-for-claims'
            element={<ApplyForClaim />}
          />          
          <Route path='/dashboard/claims' element={<Claims />} />
          <Route path='/dashboard/claims/:claimId' element={<ClaimDetails />} />
          <Route path='/dashboard/claims/:claimId/review' element={<ReviewClaim />} />

          {/* DASHBOARD PLANS */}
          <Route path='/dashboard/apply-for-plans' element={<ApplyForPlan />} />
          <Route path='/dashboard/plans' element={<Plans />} />
          <Route path='/dashboard/plans/:planId' element={<PlanDetails />} />

          {/* DASHBOARD SETTINGS */}
          <Route path='dashboard/settings' element={<Settings />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
