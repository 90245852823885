import { combineReducers } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import sessionStorage from 'redux-persist/lib/storage/session';
import { ciosUsersApi } from '../api/ciosUsersApi/ciosUsersApi';
import { authSlice } from './auth/authSlice';
import { claimsSlice } from './claims/claimsSlice';
import { plansSlice } from './plans/plansSlice';

// REDUX-PERSIST CONFIG: SESSION STORAGE
const sessionPersistConfig = {
  key: 'ciosUserSession',
  storage: sessionStorage,
};

// EXPORT ROOT REDUCER
export const rootReducer = combineReducers({
  [authSlice.name]: persistReducer(sessionPersistConfig, authSlice.reducer),
  [ciosUsersApi.reducerPath]: persistReducer(
    sessionPersistConfig,
    ciosUsersApi.reducer
  ),
  [plansSlice.name]: persistReducer(sessionPersistConfig, plansSlice.reducer),
  [claimsSlice.name]: persistReducer(sessionPersistConfig, claimsSlice.reducer),
});
