import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { useEffect } from 'react';

function View({ formData, pageId, formDataFormatter }) {
  const { setFormDataFormatter, setFormData, trackFormLoad, trackPageLoad } =
    useUserTracker();
  useEffect(() => {
    trackPageLoad(pageId);
    setFormDataFormatter(formDataFormatter);
    setFormData(formData || {});
    trackFormLoad();
  }, []);
  useEffect(() => {
    setFormData(formData || {});
  }, [formData]);
}

export default View;
