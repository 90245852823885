/* eslint-disable no-useless-catch */
import { convertFileToBlob } from './fileAsBlob';

export async function uploadFileToS3({ s3Url, file }) {
  try {
    const blobData = await convertFileToBlob(file);
    const response = await fetch(s3Url, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: blobData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`); // Throw an error if response is not ok
    }

    return response;
  } catch (e) {
    throw e;
  }
}
