import React from 'react';
import LogoImage from 'common/components/logo-image';

const LoadingPageLayout = () => {
  return (
    <div className='w-[100dvw] h-[100dvh] bg-white text-gray-600 font-normal text-base flex items-center justify-center'>
      <LogoImage />
    </div>
  );
};

export default LoadingPageLayout;
