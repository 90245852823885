const WrapInputLabel = ({ children, isRequired, className }) => {
  return (
    <label className={`flex items-center w-full gap-1 font-semibold text-sm tracking-wider uppercase ${className}`}>
      {children}{' '}
      <>
        {isRequired ? (
          <span className='text-red-500'>*</span>
        ) : (
          <span className='text-slate-400'>
            {'(Optional)'}
          </span>
        )}
      </>
    </label>
  );
};

export default WrapInputLabel;
