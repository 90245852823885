// combine formik values/errors/touched into a single object

function mergeFormikState({ values, errors, touched }) {
  const mergeObjects = (vals, errs, tchs) => {
    // Initialize the result object
    let result = {};

    // Process each value in the vals object
    Object.keys(vals).forEach((key) => {
      const value = vals[key];
      const error = errs?.[key];
      const isTouched = !!tchs?.[key];
      const displayed = true;

      if (
        value !== null &&
        typeof value === 'object' &&
        !Array.isArray(value)
      ) {
        // If the value is an object, recurse
        result[key] = mergeObjects(value, error, tchs[key]);
      } else {
        // Otherwise, construct the unified view for this key
        result[key] = {
          value,
          displayed,
          errors: error,
          touched: isTouched,
        };
      }
    });

    return result;
  };

  return mergeObjects(values, errors, touched);
}

export default mergeFormikState;
