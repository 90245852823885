import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearPlansDraft } from 'redux/features/plans/plansSlice';
import Button from 'common/components/button';
import StatusLabel from '../../shared/status-label';

const PendingPlanReg = ({ status }) => {
  // HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // HANDLERS
  const handleGoToDashboard = () => {
    dispatch(clearPlansDraft());
    navigate('/dashboard/plans');
  };
  return (
    <div className='w-full flex flex-col gap-10 text-center'>
      {/* first */}
      <div className='flex flex-col gap-6 items-center w-full'>
        {/* label */}
        <div className='flex flex-col gap-4 items-center'>
          <span className='text-[#191923] text-base font-normal'>Status:</span>

          <StatusLabel status={status} size='sm' />
        </div>

        {/* title */}
        <p className='text-3xl w-[70%] font-bold text-[#191923] mobile:w-full'>
          {`That's all for now.`}
        </p>

        {/* subtitle */}
        <p className='font-bold w-[70%] text-xl text-[#191923] mobile:w-full'>
          {`We're reviewing your information and will reach out to you in the next
          3 days if we need additional information.`}
        </p>
      </div>

      {/* message */}
      <p className='text-[#191923] text-base font-normal'>
        {`Our team is verifying the details of your application. If approved, you
        will receive an email within 5 days with your new plan number after
        processing your first payment.`}
      </p>

      {/* button */}
      <Button
        onClick={handleGoToDashboard}
        title='Got it, Return to Home'
      />
    </div>
  );
};

export default PendingPlanReg;
