import { useParams } from 'react-router-dom';
import Button from 'components/plans/download-button';
import { useGetSinglePlanQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';

// This is a temporary page to demonstrate a pdf
// Refer to the download-button component for the actual implementation

function View() {
  const { planId } = useParams();
  const { data: singlePlan, isLoading: getSinglePlanLoading } =
    useGetSinglePlanQuery(planId);

  return (
    <div className='w-full min-width-[400px] rounded-[10px] gap-5'>
      <div className='flex w-full items-center bg-[#E1E6EF] text-[#191923] text-base font-bold px-5 py-5 rounded-[10px]'>
        <Button planId={planId} />
      </div>
      <div className='flex w-full items-center bg-[#E1E6EF] text-[#191923] text-base font-bold px-5 py-5 rounded-[10px]'>
        {singlePlan && JSON.stringify(singlePlan)}
      </div>
    </div>
  );
}

export default View;
