import WrapInputLabel from 'components/shared/wrap-input-label';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  savePlansValues,
  selectPlansValues,
} from 'redux/features/plans/plansSlice';
import * as Yup from 'yup';
import ApplyButtons from 'layouts/apply-buttons';
import {
  useUpdateSinglePlanMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import FormError from 'components/shared/form-error';
import Input from 'components/shared/input';
import { extractErrorMsg } from 'utils/errors';
import {
  ApplyFormContainer,
  ApplyFormField,
  ApplyFormLayout,
} from 'layouts/apply-form';
import { Flex, Tag, Text } from '@chakra-ui/react';
import RadioGroup from 'components/shared/boolean-radio-input';
import SelectInput from 'common/components/select-input';
import { residencyStatusOptions } from 'utils/selectOptions';
import { isEmpty } from 'common/utils/isEmpty';

const PlanStepPersonalInfo = ({
  onBackClick,
  stepNumber,
  onNextClick,
  clearSearchParams,
}) => {

  // HOOKS
  const savedPlansValue = useSelector(selectPlansValues);
  console.log(savedPlansValue);

  const dispatch = useDispatch();
  const [
    // eslint-disable-next-line no-unused-vars
    updatePlan,
    { isLoading: updatePlanLoading, isSuccess: isUpdatePlanSuccess },
  ] = useUpdateSinglePlanMutation();

  // eslint-disable-next-line no-unused-vars
  const {
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
    trackCustomEvent,
  } = useUserTracker();

  // SIDE EFFECTS
  useEffect(() => {
    if (isUpdatePlanSuccess) {
      clearSearchParams();
    }
  }, [isUpdatePlanSuccess]);

  // Create option menu for visa selection
  const createResidencyOptionMenu = (obj) => {
    if (isEmpty(obj)) return [];

    return obj?.map(
      // eslint-disable-next-line no-unused-vars
      ({ label, examples, value, ...res }) => ({
      label: (
        <div className='w-full flex gap-2 justify-between'>
          <div className='text-base font-semibold'>{label}</div>
          <div className='text-base font-normal hidden gap-2 sm:flex'>
            {examples?.map((example, index) => (
              <Tag key={index} size='sm' colorScheme='gray' className='rounded-full px-3'>
                {example}
              </Tag>
            ))}
          </div>
        </div>
      ),
      value: value,
      })
    );
  };

  // Not sure why we implemented this feature, but it exists
  // const fieldsDisabled = !isEmpty(savedPlansValue) && !isEdit;
  const fieldsDisabled = false;

  return (   
    <Formik
      enableReinitialize
      initialValues={{
        jobQuitPlans: savedPlansValue?.jobQuitPlans,
        movePlans: savedPlansValue?.movePlans, 
        residencyStatus: savedPlansValue?.residencyStatus || 'citizenPermanent',
        visaExpiration: savedPlansValue?.visaExpiration?.split('T')[0],
        rentalHistory: {
          addressesLived: savedPlansValue?.rentalHistory?.addressesLived-1,
          terminationHistory: savedPlansValue?.rentalHistory?.terminationHistory,
          evictionHistory: savedPlansValue?.rentalHistory?.evictionHistory,
        }
      }}
      validationSchema={Yup.object().shape({
        jobQuitPlans: Yup.boolean().nullable().required(),
        movePlans: Yup.boolean().nullable().required(),
        residencyStatus: Yup.string().required(),
        visaExpiration: Yup.date().nullable(),
          // .when('residencyStatus', (residencyStatus, schema) => {
          //   if (residencyStatus !== 'citizenPermanent') {
          //     return schema.required();
          //   }
          //   return schema.nullable();
          // }),
        rentalHistory: Yup.object().shape({
          addressesLived: Yup.number().required(),
          terminationHistory: Yup.boolean().required(),
          evictionHistory: Yup.boolean().required(),
        }),
      })}
      onSubmit={(values) => {
        let submissionValues = {
          jobQuitPlans: values.jobQuitPlans,
          movePlans: values.movePlans,
          residencyStatus: values.residencyStatus,
          visaExpiration: values.visaExpiration,
          rentalHistory: {
            addressesLived: values.rentalHistory.addressesLived+1,
            terminationHistory: values.rentalHistory.terminationHistory,
            evictionHistory: values.rentalHistory.evictionHistory,
          },
          planId: savedPlansValue.id,          
        };

        trackFormSubmission(submissionValues);

        updatePlan(submissionValues)
          .unwrap()
          .then((data) => {
            dispatch(savePlansValues(data || {}));
            onNextClick();
            trackFormSubmissionSuccess(submissionValues);
          })
          .catch((error) => {
            const errMsg = extractErrorMsg(error, 'Update Failed');
            toast.error(errMsg);
            trackFormSubmissionError(submissionValues, errMsg);
          });
      }}
    >
      {({
        handleBlur,
        handleChange,
        setFieldValue,
        values,
        isValid,
        dirty: isDirty,
        handleSubmit,
        setFieldTouched,
      }) => {
        return (
          <Form
            onSubmit={(e) => e.preventDefault()}
            className='flex flex-col w-full h-full'
          >
            <div className='flex gap-4 w-full p-10 border-b border-black mobile:border-none mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
              <div className='flex flex-col gap-2 w-full mobile:gap-1'>
                <h1 className='text-3xl mobile:text-2xl font-bold'>
                  Personal Info
                </h1>
                <p className='font-medium text-xl mobile:text-lg'>
                Share some basic details about yourself.
                </p>
              </div>
              
              {/* Buttons */}
              <ApplyButtons
                loading={updatePlanLoading}
                stepNumber={stepNumber}
                isValid={isValid && (isDirty || savedPlansValue?.rentalHistory?.addressesLived)}
                handleSubmit={isDirty ? handleSubmit : onNextClick}
                moveOn={fieldsDisabled}
                onNextClick={onNextClick}
                onBackClick={onBackClick}
              />
            </div>

            {/* main content */}
            <div className='flex flex-col h-full w-full gap-12 p-10 overflow-scroll no-scrollbar mobile:px-2 mobile:pb-32'>
            {/* container */}
            <ApplyFormContainer>
              {/* Pre-meditated events */}
              <ApplyFormLayout>
                <Flex className='gap-5 col-span-2 justify-between items-center mobile:flex-col mobile:gap-4 mobile:items-start'>
                  <Text className='font-normal'>
                    Have you already signed or otherwise committed to an offer of employment that may require you to prematurely cancel your lease?
                  </Text>
                  <RadioGroup
                    value={values.jobQuitPlans != null ? values.jobQuitPlans.toString() : ''}
                    onChange={(value) => {
                      const booleanValue = value === 'true';
                      setFieldValue('jobQuitPlans', booleanValue);
                    }}
                    name="jobQuitPlans"
                  />
                  <FormError name='jobQuitPlans' />
                </Flex>
                <Flex className='gap-5 col-span-2 justify-between items-center mobile:flex-col mobile:gap-4 mobile:items-start'>
                  <Text className='font-normal'>
                    Are you currently aware of or committed to any other plans or decisions that may require you to prematurely cancel your lease?
                  </Text>
                  <RadioGroup
                    value={values.movePlans != null ? values.movePlans.toString() : ''}
                    onChange={(value) => {
                      const booleanValue = value === 'true';
                      setFieldValue('movePlans', booleanValue);
                    }}
                    name="movePlans"
                  />
                  <FormError name='movePlans' />
                </Flex>
              </ApplyFormLayout>

              {/* Residency Status */}
              <ApplyFormLayout>
                {/* Select Residency Status */}
                <ApplyFormField>
                  <WrapInputLabel isRequired>Residency Status</WrapInputLabel>
                  <SelectInput
                    disabled={fieldsDisabled}
                    placeholderText='Select your residency status'
                    options={createResidencyOptionMenu(residencyStatusOptions)}
                    value={createResidencyOptionMenu(residencyStatusOptions).find(
                      (status) => status.value === values.residencyStatus
                    )}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFieldValue('residencyStatus', selectedOption.value).then(() => {
                          setFieldTouched('residencyStatus');
                        });
                      } else {
                        // Handle case when select is cleared
                        setFieldValue('residencyStatus', null).then(() => {
                          setFieldTouched('residencyStatus');
                        });
                      }
                    }}
                    onBlur={handleBlur}
                    name='residencyStatus'
                  />
                  <FormError name='residencyStatus' />
                </ApplyFormField>

                {/* Visa Expiration Date */}
                {values.residencyStatus != 'citizenPermanent' && (
                  <ApplyFormField>
                    <WrapInputLabel isRequired>Visa Expiration Date</WrapInputLabel>
                    <Input
                      type='date'
                      name='visaExpiration'
                      value={values.visaExpiration}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={fieldsDisabled}
                    />
                    <FormError name='visaExpiration' />
                  </ApplyFormField>
                )}
              </ApplyFormLayout>

              {/* Rental History */}
              <ApplyFormLayout>
                <Text className='font-semibold col-span-2'>
                    Information About Your Past 3 Years of Rental History
                  </Text>
                <ApplyFormField>
                  <WrapInputLabel isRequired>How Many Times Have You Moved?</WrapInputLabel>
                  <Input
                    type='number'
                    name='rentalHistory.addressesLived'
                    value={values.rentalHistory.addressesLived}
                    placeholderText='Enter a number'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={fieldsDisabled}
                    suffix='times'
                  />
                  <FormError name='rentalHistory.addressesLived' />
                </ApplyFormField>
                <Flex className='gap-5 col-span-2 justify-between items-center mobile:flex-col mobile:gap-4 mobile:items-start'>
                  <Text className='font-normal'>
                    Have you terminated more than 2 leases in the past 3 years?
                  </Text>
                  <RadioGroup
                    value={values.rentalHistory.terminationHistory != null ? values.rentalHistory.terminationHistory.toString() : ''}
                    onChange={(value) => {
                      const booleanValue = value === 'true';
                      setFieldValue('rentalHistory.terminationHistory', booleanValue);
                    }}
                    name="rentalHistory.terminationHistory"
                  />
                  <FormError name='rentalHistory.terminationHistory' />
                </Flex>
                <Flex className='gap-5 col-span-2 justify-between items-center mobile:flex-col mobile:gap-4 mobile:items-start'>
                  <Text className='font-normal'>
                    Have you been evicted in the past 3 years?
                  </Text>
                  <RadioGroup
                    value={values.rentalHistory.evictionHistory != null ? values.rentalHistory.evictionHistory.toString() : ''}
                    onChange={(value) => {
                      const booleanValue = value === 'true';
                      setFieldValue('rentalHistory.evictionHistory', booleanValue);
                    }}
                    name="rentalHistory.evictionHistory"
                  />
                  <FormError name='rentalHistory.evictionHistory' />
                </Flex>
              </ApplyFormLayout>
            </ApplyFormContainer>            

            {/* <Tracker
              values={values}
              errors={errors}
              touched={touched}
              formName='stepThree'
            /> */}
            </div>            
          </Form>
        );
      }}
    </Formik>
  );
};

export default PlanStepPersonalInfo;
