// Create some standard layouts for apply plan form and apply claim form

const ApplyFormContainer = ({ children, className }) => {
  return (
    <div className={`flex flex-col w-full ${className} -mt-8 divide-y divide-slate-200`}>
      {children}
    </div>
  )
}

const ApplyFormLayout = ({ children, className }) => {
  return (
    <div className={`grid grid-cols-2 gap-5 items-start w-full pb-7 pt-8 mobile:gap-y-7 ${className}`}>
      {children}
    </div>                
  )
}

const ApplyFormField = ({ children, small=false, className }) => {
  return (
    <div className={`flex flex-col gap-2 w-full ${small ? 'col-span-1 mobile:col-span-2' : 'col-span-2'} ${className}`}>
      {children}
    </div>
  )
}

export { ApplyFormContainer, ApplyFormLayout, ApplyFormField }

