import { ErrorMessage } from 'formik';
import React from 'react';

const FormError = ({ name }) => {
  return (
    <ErrorMessage
      className='text-sm text-red-500 font-medium'
      component='div'
      name={name}
    />
  );
};

export default FormError;
