import React from 'react';

const EmptyData = ({ title = 'Nothing to see here!' }) => {
  return (
    <div className='w-full min-h-[50dvh] flex items-center justify-center text-2xl font-semibold text-gray-400'>
      {title}
    </div>
  );
};

export default EmptyData;
