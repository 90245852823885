import React from 'react';

const ModalBgLayout = ({ children }) => {
  return (
    <div className='w-full h-full rounded-xl bg-white py-10 flex flex-col items-center justify-center'>
      {/* wrapper */}
      <div className='w-[70%] max-h-[80vh] overflow-y-scroll no-scrollbar'>
        {children}
      </div>
    </div>
  );
};

export default ModalBgLayout;
