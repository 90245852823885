import useTracker from 'common/utils/user-tracker/useTracker';
import mergeFormikState from 'common/utils/user-tracker/formik-merge';

function formDataFormatter({ values, errors, touched }) {
  let data = mergeFormikState({ values, errors, touched });
  if (data?.password?.value && data?.password?.value?.length > 0) {
    data.password.value = '*'.repeat(data.password.value.length);
  } else {
    data.password.value = '';
  }

  if (data?.repeatPassword?.value && data?.repeatPassword?.value?.length > 0) {
    data.repeatPassword.value = '*'.repeat(data.repeatPassword.value.length);
  } else {
    data.repeatPassword.value = '';
  }

  return data;
}

function View(formData) {
  useTracker({ formData, pageId: 'signup', formDataFormatter });
}

export default View;
