import FinalizeClaim from 'components/review-claim/finalize-claim';
import ResolvedClaim from 'components/review-claim/resolved-claim';

const ReviewClaimLayout = ({ claimId, claimData }) => { 
  return (
    <section className='h-full w-full flex flex-col items-center border border-black divide-y divide-black rounded-lg mobile:border-none mobile:rounded-none mobile:divide-slate-200' >
      {
        claimData?.submission?.confirmedDate ? (
          <FinalizeClaim claim={claimData} claimId={claimId} />
        ) : (
          <ResolvedClaim claim={claimData} claimId={claimId} />
        )
      }
    </section>
  );
};

export default ReviewClaimLayout;
