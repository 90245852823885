import { useEffect } from 'react';
import { useRetrieveUserDetailsQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import VerifyIdentity from '../verify-identify';

const VerificationSettings = () => {
  // HOOKS
  const { data: userDetails, isLoading } = useRetrieveUserDetailsQuery();

  useEffect(() => {}, [userDetails, isLoading]);

  return (
    <div className='w-full flex flex-col gap-12 py-6 px-10 mobile:px-5'>
      {/* title */}
      <div className='flex flex-col w-full'>
        <h3 className='text-2xl text-black font-semibold'>Verification</h3>
        <h5 className='text-[#637381] text-base font-normal'>
          See your verification status
        </h5>
      </div>

      {/* content */}
      <div className='w-full flex justify-center'>
        {!isEmpty(userDetails) && (
          <VerifyIdentity verificationStatus={userDetails?.stripeKyc?.status} />
        )}
      </div>
    </div>
  );
};

export default VerificationSettings;
