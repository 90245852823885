import React from 'react';
import {
  Link as ReactRouterLink,
  NavLink as ReactRouterNavLink,
} from 'react-router-dom';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';

function withLinkTracking(WrappedComponent) {
  return function TrackedLinkComponent({
    children,
    linkId,
    onClick,
    ...props
  }) {
    const { trackLink } = useUserTracker();

    const handleClick = (event) => {
      if (linkId) {
        trackLink(typeof children === 'string' ? children : linkId);
      }
      if (onClick) {
        onClick(event);
      }
    };

    // Render the wrapped component with the additional props
    return (
      <WrappedComponent {...props} onClick={handleClick}>
        {children}
      </WrappedComponent>
    );
  };
}

export const Link = withLinkTracking(ReactRouterLink);
export const NavLink = withLinkTracking(ReactRouterNavLink);
