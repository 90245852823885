import { Link, useParams } from 'react-router-dom';
import DocumentPreview from 'common/components/document-preview';
import EmptyData from 'components/shared/empty-data';
import LoadingSpinner from 'components/shared/loading-spinner';
import StatusLabel from 'components/shared/status-label';
import DashboardMainLayout from 'layouts/dashboard-main';
import { useGetSingleClaimQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { claimPaymentPreferenceEnum, claimTypeEnum, statusEnum } from 'utils/enums';
import { formatDateToHumanTime } from 'common/utils/formDateToHumanTime';
import { isEmpty } from 'common/utils/isEmpty';
import ContactCard from 'components/shared/contact-card';
import { Flex, Text } from '@chakra-ui/react';
import { InfoCard, InfoField, InfoGrid } from 'common/components/info-card';
import Button from 'common/components/button';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@chakra-ui/react';
import { FaCheck, FaChevronRight } from 'react-icons/fa6';
import { ProgressCard, ProgressIcon } from 'components/shared/progress-card';

const ClaimDetails = () => {
  // HOOKS
  const { claimId } = useParams();
  const { data: singleClaim, isLoading: getSingleClaimLoading } =
    useGetSingleClaimQuery(claimId);

  // DATA INITIALIZATION
  const navigate = useNavigate();
  const statusMessage = {
    [statusEnum.submitted]: 'Your claim has been submitted successfully',
    [statusEnum.inReview]: 'Your claim is under review and the result will be emailed to you within 2 business days. Please check the progress here or your email for updates.',
    [statusEnum.Approved]: 'Your claim has been approved. Please read the  next steps and confirm.',
    [statusEnum.Confirmed]: 'Your claim has been confirmed and is now finalized. If you are moving out, you will receive move-out instructions shortly.',    
    [statusEnum.Resolved]: singleClaim?.status === statusEnum.Confirmed 
      ? 'Your claim has been resolved.'
      : 'Your claim has been resolved. Please review the resolution and confirm.',
    [statusEnum.Completed]: 'Your claim is now complete. Please save the details for your records.',
  }

  // Check if the claim has been submitted before
  const hasSubmitted = singleClaim?.statusChanges?.some(
    (statusChange) => statusChange.status === statusEnum.submitted
  );

  return (
    <DashboardMainLayout title='Claim Details'>
      <div className='flex flex-col w-full gap-6'>
        {/* breadcrumb */}
        <div className='flex gap-x-3  gap-y-1 items-center flex-wrap'>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE]'
          >
            Home
          </Link>
          <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
          <Link
            to='/dashboard/claims'
            className='text-[#CED0CE] text-base font-normal'
          >
            Claims{' '}
          </Link>
          <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
          <Link
            to=''
            className='text-[#191923] text-base font-normal uppercase'
          >
            {singleClaim?.id}
          </Link>
        </div>

        {/* body content */}
        {getSingleClaimLoading ? (
          <LoadingSpinner />
        ) : isEmpty(singleClaim) ? (
          <EmptyData />
        ) : (
          <>
            {/* Progress */}
            {singleClaim?.statusChanges?.length > 0 && (
            <Flex className='w-full flex-col gap-4 mt-6'>
              <Flex className='gap-4 items-center justify-between'>
                <h2 className='text-2xl font-bold'>Claim Progress</h2>
              </Flex>
              <InfoCard title='Progress'>
                <InfoGrid>
              <Flex className='w-full flex-col gap-1 py-1 col-span-4'>
              {singleClaim?.statusChanges?.map((statusChange, index) => (
                <>
                  <Flex key={index} className='w-full gap-4 items-start'>
                    <Flex className='flex-col h-full items-center'>
                    <ProgressIcon
                      CardIcon={FaCheck}
                      className='px-4 py-4 mt-1'
                    />                    
                    {index < singleClaim?.statusChanges?.length - 1 && (
                      <div className='h-full min-h-[2rem] mt-2 flex w-10 justify-center'>
                        <div className='h-full border-l-2 border-slate-200' />
                      </div>
                    )}
                    </Flex>
                    <Flex className='w-full gap-1 items-start flex-col mb-4'>
                      <Flex className='w-full gap-2 justify-between items-center'>
                        <Text className='text-xl font-bold capitalize'>
                          {statusChange.status}
                        </Text>
                        <Text className='text-base text-slate-500 min-w-fit mr-2'>
                          {formatDateToHumanTime(statusChange.updatedAt)}
                        </Text>  
                      </Flex>                      
                      <Text className='text-base'>
                        {statusMessage[statusChange.status]}
                      </Text>
                      {/* Show button to view completed review if the status is resolved */}
                      {statusChange.status === statusEnum.Resolved && singleClaim?.status !== statusEnum.Confirmed ? (
                        <Button
                          title='View Completed Review'
                          onClick={() => navigate(`/dashboard/claims/${singleClaim?.id}/review`)}                            
                          className='mt-4 mb-2'
                          wFull={false}                     
                        />
                      ) : null}
                      {statusChange.status === statusEnum.Confirmed ? (
                        <Button
                          title='View Confirmed Resolution'
                          onClick={() => navigate(`/dashboard/claims/${singleClaim?.id}/review`)}                            
                          className='mt-4'
                          wFull={false}
                          theme='secondary'                  
                        />
                      ) : null}
                    </Flex>                    
                  </Flex>                  
                  </>
                ))}
              </Flex>
              </InfoGrid>
              </InfoCard>
            </Flex>
            )}       

            {/* Submitted Information */}
            {hasSubmitted && (
            <Flex className='w-full flex-col gap-4 mt-6'>
              {/* header */}
              <Flex className='gap-4 items-center justify-between'>
                <h2 className='text-2xl font-bold'>
                  Your Submitted Claim Details
                </h2>                  
              </Flex>

              {/* submitted information in card */}              
              <InfoCard title='Submitted Information'>
                {/* shared information for all claim types */}
                <InfoGrid>
                  <InfoField title='Claim Number' className='col-span-2 sm:col-span-1'>{singleClaim?.id}</InfoField>
                  <InfoField title='Plan ID' className='col-span-2 sm:col-span-1'>
                    <Link className='underline hover:opacity-50' to={`/dashboard/plans/${singleClaim?.planId?.id}`}>
                      {singleClaim?.planId?.id}
                    </Link>                    
                  </InfoField>
                  <InfoField title='Lease' className='col-span-2'>
                    {singleClaim?.planId?.lease?.address?.streetAddress1}, {singleClaim?.planId?.lease?.address?.city}
                  </InfoField>
                </InfoGrid>

                <InfoGrid>
                  <InfoField title='Claim Type' className='col-span-4'>
                    {singleClaim?.claimType === claimTypeEnum.cancelAnytime
                      ? 'Cancel my upcoming lease'
                      : 'Move out early and cancel the reminder of my lease'}
                  </InfoField>
                </InfoGrid>

                <InfoGrid>
                  {/* Only show date fields when it's actually relevant */}
                  {singleClaim?.claimType === claimTypeEnum.leaveAnytime ? (
                    <>
                      <InfoField title='Earliest Move Out Date' className='col-span-2 sm:col-span-1'>
                        {formatDateToHumanTime(singleClaim?.submission?.moveOutStart)}
                      </InfoField>
                      <InfoField title='Latest Move Out Date' className='col-span-2 sm:col-span-1'>
                        {formatDateToHumanTime(singleClaim?.submission?.moveOutEnd)}
                      </InfoField>
                    </>
                  ): null}
                  <InfoField title='Payment Prference' className='col-span-2 sm:col-span-1'>
                    {singleClaim?.submission?.paymentPreference === claimPaymentPreferenceEnum.DIRECT ? 'Direct' : 'Reimbursement'}
                  </InfoField>                  
                </InfoGrid>
                
                <InfoGrid>
                  <InfoField title='Reason for Move Out' className='col-span-4'>
                    {singleClaim?.submission?.moveReason}
                  </InfoField>
                </InfoGrid>             
              </InfoCard>             
            </Flex>
             )}
          </>
        )}
      </div>

      {/* Contact Section */}
      <ContactCard title='Have Questions About Your Claim?'>
        {[
          statusEnum.Draft,
          statusEnum.submitted,
          statusEnum.inReview,
        ].includes(singleClaim?.status)? (
          <Text>
            We are now reviewing your claims based on our terms and notify you of the decision through the app. If you have any questions during the process, feel free to reach out to our customer service team anytime with the information below.
          </Text>
        ) : (
          <Text>
            If you have any questions about your claim, please reach out to our customer service team anytime with the information below.
          </Text>
        )}
      </ContactCard>
    </DashboardMainLayout>
  );
};

export default ClaimDetails;
