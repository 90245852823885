import useTracker from 'common/utils/user-tracker/useTracker';
import { hidePassword } from '../../../utils/password';
import mergeFormikState from 'common/utils/user-tracker/formik-merge';

function formDataFormatter({ values, errors, touched }) {
  let data = mergeFormikState({ values, errors, touched });

  if (data?.password?.value) {
    data.password.value = hidePassword(data?.password?.value);
  } else {
    data.password.value = '';
  }

  if (data?.repeatPassword?.value) {
    data.repeatPassword.value = hidePassword(data?.repeatPassword?.value);
  } else {
    data.repeatPassword.value = '';
  }
  return data;
}

function View(formData) {
  useTracker({ formData, formDataFormatter, pageId: 'resetPassword' });
}

export default View;
