import useTracker from 'common/utils/user-tracker/useTracker';
import { hidePassword } from '../../../utils/password';
import mergeFormikState from 'common/utils/user-tracker/formik-merge';

function formDataFormatter({ values, errors, touched }) {
  let data = mergeFormikState({ values, errors, touched });
  if (data?.currentPassword?.value) {
    data.currentPassword.value = hidePassword(data?.currentPassword?.value);
  } else {
    data.currentPassword.value = '';
  }

  if (data?.newPassword?.value) {
    data.newPassword.value = hidePassword(data?.newPassword?.value);
  } else {
    data.newPassword.value = '';
  }

  if (data?.repeatPassword?.value) {
    data.repeatPassword.value = hidePassword(data?.repeatPassword?.value);
  } else {
    data.repeatPassword.value = '';
  }
  return data;
}

function View(formData) {
  useTracker({ formData, pageId: 'accountSettings', formDataFormatter });
}

export default View;
