import WrapInputLabel from 'components/shared/wrap-input-label';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useUpdatePasswordMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import {
  LOWERCASECASE_REGEX,
  NOT_STRICT_DIGIT_REGEX,
  UPPERCASE_REGEX,
} from 'utils/regex';
import Button from 'common/components/button';
import FormError from 'components/shared/form-error';
import PasswordInput from 'components/shared/password-input';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import Tracker from 'components/settings/password-form/tracker';
import { GiCheckMark } from 'react-icons/gi';
import { GoDotFill } from 'react-icons/go';

const PasswordForm = () => {
  // HOOKS
  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();
  const {
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
  } = useUserTracker();

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        repeatPassword: '',
      }}
      validationSchema={Yup.object({
        currentPassword: Yup.string().required('Required'),

        newPassword: Yup.string()
          .required('Required')
          .min(8, 'Must be at least 8 characters long')
          .matches(NOT_STRICT_DIGIT_REGEX, 'Must contain a number')
          .matches(LOWERCASECASE_REGEX, 'Must contain a lowercase')
          .matches(UPPERCASE_REGEX, 'Must contain an uppercase'),
        repeatPassword: Yup.string()
          .required('Required')
          .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
      })}
      onSubmit={(values, { resetForm }) => {
        trackFormSubmission({});
        updatePassword({
          newPassword: values.newPassword,
          oldPassword: values.currentPassword,
        })
          .unwrap()
          .then(() => {
            trackFormSubmissionSuccess({});
            toast.info('Your password has been update!');
            resetForm();
          })
          .catch((e) => {
            trackFormSubmissionError(
              {},
              e.data?.msg || `Couldn't update password`
            );
            toast.error('Failed to update password!');
          });
      }}
    >
      {({
        isValid,
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldTouched,
      }) => (
        <div className='w-full flex flex-col gap-5 pt-8'>
          {/* texts */}
          <div className='flex flex-col gap-1'>
            <h4 className='text-2xl font-semibold text-black'>Password</h4>
            <h5 className='font-normal text-base text-[#637381]'>
              Change your current password
            </h5>
          </div>

          {/* form */}
          <form
            onSubmit={(e) => e.preventDefault()}
            className='w-full flex flex-col gap-8'
          >
            {/* inputs */}
            <div className='w-full flex flex-col gap-5'>
              {/* current password */}
              <div className='w-full flex flex-col gap-2'>
                <WrapInputLabel isRequired>
                    Current Password
                  </WrapInputLabel>
                <PasswordInput
                  name='currentPassword'
                  onChange={(e) => {
                    setFieldTouched('currentPassword');
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  value={values.currentPassword}
                  placeholderText='Enter your current password'
                />
                <FormError name='currentPassword' />
              </div>

              {/* new password */}
              <div className='w-full flex flex-col gap-2'>
                <WrapInputLabel isRequired>
                    New Password
                  </WrapInputLabel>
                <PasswordInput
                  name='newPassword'
                  onChange={(e) => {
                    setFieldTouched('newPassword');
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  value={values.newPassword}
                  placeholderText='Enter your new password'
                />
                <FormError name='newPassword' />
              </div>

              {/* requirements for the password fields */}
              <ul className='flex flex-col gap-1 text-sm text-[#191923]'>
                <li className='font-semibold'>Password requirements:</li>

                {/* check if password is up to 8 chars */}
                <li
                  className={`inline-flex items-center gap-2 ${
                    values.newPassword.length >= 8 && 'text-green-500'
                  }`}
                >
                  {values.newPassword.length >= 8 ? (
                    <GiCheckMark />
                  ) : (
                    <GoDotFill />
                  )}
                  Must be at least 8 characters
                </li>

                {/* check if password contains atleast one uppercase letter */}
                <li
                  className={`inline-flex items-center gap-2 ${
                    values.newPassword.match(UPPERCASE_REGEX) &&
                    'text-green-500'
                  }`}
                >
                  {values.newPassword.match(UPPERCASE_REGEX) ? (
                    <GiCheckMark />
                  ) : (
                    <GoDotFill />
                  )}
                  Must contain at least one UPPERCASE letter
                </li>

                {/* check if password contains atleast one lowercase letter */}
                <li
                  className={`inline-flex items-center gap-2 ${
                    values.newPassword.match(LOWERCASECASE_REGEX) &&
                    'text-green-500'
                  }`}
                >
                  {values.newPassword.match(LOWERCASECASE_REGEX) ? (
                    <GiCheckMark />
                  ) : (
                    <GoDotFill />
                  )}
                  Must contain at least one lowercase letter
                </li>

                {/* check if password contains atleast one number */}
                <li
                  className={`inline-flex items-center gap-2 ${
                    values.newPassword.match(NOT_STRICT_DIGIT_REGEX) &&
                    'text-green-500'
                  }`}
                >
                  {values.newPassword.match(NOT_STRICT_DIGIT_REGEX) ? (
                    <GiCheckMark />
                  ) : (
                    <GoDotFill />
                  )}
                  Must contain at least one number
                </li>
              </ul>

              {/* confirm password */}
              <div className='w-full flex flex-col gap-2'>
                <WrapInputLabel isRequired>
                    Confirm Password
                  </WrapInputLabel>
                <PasswordInput
                  name='repeatPassword'
                  onChange={(e) => {
                    setFieldTouched('repeatPassword');
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  value={values.repeatPassword}
                  placeholderText='Re-enter your new password'
                />
                <FormError name='repeatPassword' />
              </div>
            </div>

            {/* button */}
            <Button
              linkId='changePasswordBtn'
              loading={isLoading}
              onClick={handleSubmit}
              disabled={!isValid || isLoading}
              title='Change password'
            />
            <Tracker
              values={values}
              errors={errors}
              touched={touched}
              formName='changePassword'
            />
          </form>
        </div>
      )}
    </Formik>
  );
};

export default PasswordForm;
