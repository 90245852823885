import { MdKeyboardArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import StatusLabel from 'components/shared/status-label';
import { Icon } from '@chakra-ui/react';
import { claimTypeEnum } from 'common/utils/enums';
import { statusEnum } from 'utils/enums';

const ClaimsTable = ({ claims, statuses }) => {
  // DATA INTIAILIZATION
  const navigate = useNavigate();

  console.log(claims);

  return (
    <table className='flex flex-col w-full'>
      {/* body */}
      <tbody className='w-full rounded-lg overflow-hidden border border-gray-200 divide-y'>
        {claims.map((claim, index) => (           
          <tr
            key={index}
            onClick={() => claim.status === statusEnum.Draft ? 
              navigate(`/dashboard/apply-for-claims/${claim.id}`) : 
              claim.status !== statusEnum.Cancelled ?
              navigate(`/dashboard/claims/${claim.id}`) : null
            }
            className='grid grid-cols-[1fr_1fr_0.5fr_0.5fr] gap-4 w-full hover:bg-gray-50 px-3 py-2 cursor-pointer mobile:flex mobile:flex-col mobile:gap-0'
          >
            {/* Claim ID */}
            <td className='flex flex-col gap-1 w-full p-3 text-[#191923] text-base font-medium items-start'>
              <span className='uppercase tracking-wider font-bold text-sm' >
                Claim Number
              </span>
              <span className='uppercase tracking-wider text-lg'>
                {claim?.id}
              </span>
            </td>            

            {/* Plan Number */}
            <td className='flex flex-col gap-1 w-full p-3 text-[#191923] text-base font-medium items-start'>
              <span className='uppercase tracking-wider font-bold text-sm' >
                Plan Number
              </span>
              <span className='uppercase tracking-wider text-lg'>
                {claim?.planId}
              </span>
            </td>

            {/* Last Updated */}
            <td className='flex flex-col gap-1 w-full p-3 text-[#191923] text-base font-medium items-start'>
              <span className='uppercase tracking-wider font-bold text-sm' >
                Last Updated
              </span>
              <span className='uppercase tracking-wider text-lg'>
                {formatDateToHumanDate(claim?.statusChanges?.at(-1)?.updatedAt)}
              </span>
            </td>

            {/* Status */}
            <td className='w-full p-3 flex items-center'>
              <div className='gap-2 inline-flex justify-between w-full items-center'>
                <StatusLabel status={claim.status} size='sm' />
                <Icon as={MdKeyboardArrowRight} boxSize={4} className='mobile:hidden'/>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ClaimsTable;
