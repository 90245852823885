import React from 'react';

const PageNotFound = () => {
  return (
    <div className='w-screen h-screen flex flex-col items-center justify-center text-7xl font-extrabold text-[#191923]'>
      404 Page not found
    </div>
  );
};

export default PageNotFound;
