import { Link } from 'common/utils/user-tracker/react-router-dom';

const DashboardCard = ({ Icon, path = '', title = '' }) => {
  return (
    <Link linkId={title} to={path}>
      <div className='flex border border-[#6A6C70] rounded-[10px] px-5 mobile:px-3 py-10 mobile:py-5 flex-col gap-6 mobile:gap-3 items-center justify-center text-center hover:bg-slate-100'>
        <Icon className='text-[#191923] mobile:text-[#191923] mobile:text-4xl text-[46px]' />
        <span className='text-3xl text-[#191923] font-bold mobile:text-xl'>
          {title}
        </span>
      </div>
    </Link>
  );
};

export default DashboardCard;
