import React from 'react';
import { useClipboard, useToast, Icon, Flex } from '@chakra-ui/react';
import { FaCopy } from 'react-icons/fa6';

const ClipboardButton = ({ text, showIcon=true, ...props }) => {
  const { onCopy, hasCopied } = useClipboard(text);
  const toast = useToast();

  const handleCopy = () => {
    onCopy();
    toast({
      title: "Copied to clipboard",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Flex size='md' onClick={handleCopy} {...props} className='flex items-center gap-4 cursor-pointer hover:opacity-80 hover:underline'>
      <span className='text-md'>{text}</span>
      {showIcon && <Icon as={FaCopy} boxSize={4} />}
    </Flex>
  );
};

export default ClipboardButton;