import { Formik } from 'formik';
import { useRef } from 'react';
import { useUpdateClaimMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { toast } from 'react-toastify';
import ApplyButtons from 'layouts/apply-buttons';
import { ApplyFormContainer, ApplyFormField, ApplyFormLayout } from 'layouts/apply-form';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Radio, RadioGroup, Text } from '@chakra-ui/react';
import WrapInputLabel from 'components/shared/wrap-input-label';
import SelectInput from 'common/components/select-input';
import FormError from 'components/shared/form-error';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { extractErrorMsg } from 'utils/errors';
import ContactCard from 'components/shared/contact-card';

const Type = ({ claim }) => {
  const formikRef = useRef(null);
  const [
    updateClaim,
    { data: updatedClaimData, isLoading: updateClaimLoading },
  ] = useUpdateClaimMutation();
  const navigate = useNavigate();
  const {
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
  } = useUserTracker();

  // DATA INITIALIZATION
  // Check what coverages are on the plan for the claim
  const hasCancelAnytime = claim?.planId?.coverage?.cancelAnytime?.payment?.stripe?.id;
  const hasLeaveAnytime = claim?.planId?.coverage?.leaveAnytime?.payment?.onetime?.stripe?.id || claim?.planId?.coverage?.leaveAnytime?.payment?.subscription?.stripe?.id;

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        claimType: claim?.claimType,
      }}
      validationSchema={Yup.object().shape({
        claimType: Yup.string().required('Required'),
      })}
      onSubmit={(values) => {
        let submissionValues = {
          claimId: claim.id,
          claimType: values.claimType,
          submission: {
            ...claim?.submission,
          },
        };
        trackFormSubmission(submissionValues);
        updateClaim(submissionValues)
          .unwrap()
          .then(() => {
            navigate(`/dashboard/apply-for-claims/${claim.id}`);
            trackFormSubmissionSuccess(submissionValues);
          })
          .catch((error) => {
            const errMsg = extractErrorMsg(error, 'Update Failed');
            toast.error(errMsg);
            trackFormSubmissionError(submissionValues, errMsg);
          });
      }}
    >
      {({
        handleBlur,
        handleChange,
        setFieldValue,
        values,
        handleSubmit,
        isValid,
        setFieldTouched,
      }) => {
        return (
          <>
            {/* Header Area */}
            <div className='flex gap-4 w-full p-10 mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
              <div className='flex flex-col gap-2 w-full mobile:gap-1'>
                <h1 className='text-3xl mobile:text-2xl font-bold'>
                  Claim Type
                </h1>
                <p className='font-medium text-xl mobile:text-lg'>
                  Please let us know what type of claim you are looking to submit.
                </p>
              </div>
              
              {/* Buttons */}
              <ApplyButtons
                loading={updateClaimLoading}
                isValid={isValid && values.claimType !== 'none'}
                moveOn={false}
                handleSubmit={handleSubmit}
                disableBack
              />              
            </div>

            {/* Main Area */}
            <div className='flex flex-col h-full w-full gap-12 p-10 overflow-scroll no-scrollbar mobile:px-2 mobile:pb-32'>

              {/* container */}
              <ApplyFormContainer>                
                <ApplyFormLayout>                  
                  <Text className='font-semibold col-span-2'>
                    Choose an option from the list below:
                  </Text>
                  <ApplyFormField>
                    <WrapInputLabel isRequired>Claim Type</WrapInputLabel>

                    <RadioGroup
                      className='flex flex-col gap-4 pt-2'
                      onChange={(option) => {
                        if (option) {
                          setFieldValue('claimType', option).then(() => {
                            setFieldTouched('claimType');
                          });
                        } else {
                          // Handle case when select is cleared
                          setFieldValue('claimType', null).then(() => {
                            setFieldTouched('claimType');
                          });
                        }  
                      }}
                    >
                      {hasLeaveAnytime && (
                        <Flex className='rounded-md border border-slate-200 divide-y divide-slate-200 p-4'>
                          <Radio value='leaveAnytime' size='lg'>
                            <Text className='ml-2'>
                              Move out early and cancel the reminder of my lease at {claim?.planId?.lease?.address?.streetAddress1} in {claim?.planId?.lease?.address?.city}, {claim?.planId?.lease?.address?.state}
                            </Text>
                          </Radio>
                        </Flex>
                      )}
                      
                      {hasCancelAnytime && (
                        <Flex className='rounded-md border border-slate-200 divide-y divide-slate-200 p-4'>
                          <Radio value='cancelAnytime' size='lg'>
                            <Text className='ml-2'>
                              Cancel my upcoming lease at {claim?.planId?.lease?.address?.streetAddress1} in {claim?.planId?.lease?.address?.city}, {claim?.planId?.lease?.address?.state}
                            </Text>
                          </Radio>
                        </Flex>
                      )}                      
                    </RadioGroup>

                    <Accordion 
                      // defaultIndex={!savedPlansValue.property && [0]}
                      allowToggle className='border rounded-md mt-4'
                    >
                      <AccordionItem className='border-t-0 border-b rounded-b-md'>
                        <AccordionButton className='flex w-full gap-2 justify-between py-3'>
                          <Text className='font-semibold text-base text-left'>
                            Don't see the option you're looking for?
                          </Text>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel className='border-t pt-5'>
                          <Text className='text-base mb-3'>
                            If you don't see the option you're looking for, please reach out to us directly.
                          </Text>
                          {/* Can't find property */}
                          <ContactCard small />
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>

                    <FormError name='claimType' />
                  </ApplyFormField>
                </ApplyFormLayout>
              </ApplyFormContainer>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default Type;
