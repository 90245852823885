import moment from 'moment';

// Quick util to calculate the term (in months) between two dates.
export const getTerm = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return 12;
  }

  let start = moment(startDate);
  let end = moment(endDate);

  return end.diff(start, "months");
}
