import { Icon } from "@chakra-ui/react"
import Button from "common/components/button"
import LoadingSpinner from "components/shared/loading-spinner"

const SetupCard = ({
  title,
  subtitle,
  message,
  buttonTitle,
  onClick,
  className,
  icon,
}) => {
  return (
    <div className={`flex flex-col gap-4 items-center text-center p-4 mobile:border mobile:rounded-md mobile:py-8 ${className}`}>

      {/* Show an Icon or Loading Spinner */}
      {icon 
        ? <Icon as={icon} className='h-10 text-5xl mb-4 opacity-25'/>
        : <LoadingSpinner className='h-10 mb-4 flex' />
      }
      
      <h2 className='text-2xl font-bold'>
        {title}
      </h2>
      <p className='text-lg font-bold'>
        {subtitle}
      </p>
      <p className='mb-4'>
        {message}
      </p>

      {/* Show button if defined */}
      {buttonTitle && onClick && <Button onClick={onClick} title={buttonTitle} />}
    </div>
  )
}

export { SetupCard }
