import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  claimsDraftValue: {},
  claimsStep: 1,
  selectedClaimType: 1,
};

export const claimsSlice = createSlice({
  name: 'claims',
  initialState,
  reducers: {
    saveDraftClaims: (state, { payload }) => ({
      claimsDraftValue: payload.claimsDraftValue,
      claimsStep: payload.claimsStep,
      selectedClaimType: payload.selectedClaimType,
    }),
    clearClaimsDraft: () => initialState,
  },
});

// ACTIONS
export const { saveDraftClaims, clearClaimsDraft } = claimsSlice.actions;

// SELECTORS
export const selectClaimsDraftValue = (state) => state.claims.claimsDraftValue;
export const selectClaimsStep = (state) => state.claims.claimsStep;
export const selectClaimType = (state) => state.claims.selectedClaimType;

// REDUCER
export default claimsSlice.reducer;
