import { toast } from 'react-toastify';
import { Card, Text, Flex, HStack, Icon } from '@chakra-ui/react';
import { FaEnvelope, FaCopy, FaPhone } from 'react-icons/fa6';

const MiniCard = ({ className }) => {
  // Copy to Clipboard
  const copyToClipboard = (text, name) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success(`Copied ${name || text} to clipboard`);
      },
      (err) => {
        console.error('Failed to copy text: ', err);
      }
    );
  };

  return(
    <Flex
          className={`text-base flex-col w-full border border-slate-200 rounded-md divide-y divide-slate-200 ${className}`}
        >
          {/* email */}
          <Flex
            className='flex justify-between bg-slate-100 px-4 py-2 cursor-pointer hover:bg-slate-200 flex-col gap-1 sm:flex-row sm:gap-4'
            onClick={(e) => {
              copyToClipboard('members@releaserent.com', 'email');
            }}
          >
            <Flex className='gap-4 items-center'>
              <Icon as={FaEnvelope} boxSize={3} />
              <Text className='font-bold'>Email</Text>
            </Flex>

            <Flex className='gap-2 items-center ml-7 sm:ml-0'>
              <Icon as={FaCopy} boxSize={3} className='mobile:hidden'/>
              <Text>members@releaserent.com</Text>
            </Flex>
          </Flex>

          {/* phone */}
          <Flex
            className='flex justify-between bg-slate-100 px-4 py-2 cursor-pointer hover:bg-slate-200 flex-col gap-1 sm:flex-row sm:gap-4'
            onClick={(e) => {
              copyToClipboard('(701) 515-9759', 'phone number');
            }}
          >
            <Flex className='gap-4 items-center'>
              <Icon as={FaPhone} boxSize={3} />
              <Text className='font-bold'>Phone or Text</Text>
            </Flex>
            <Flex className='gap-2 items-center ml-7 sm:ml-0'>
              <Icon as={FaCopy} boxSize={3} className='mobile:hidden' />
              <Text>{`(701) 515-9759`}</Text>
            </Flex>
          </Flex>
        </Flex>
  )
}

const ContactCard = ({
  title = 'Still Have Questions?',
  children = 'Our team is here to help you understand which plan is the best fit for you. Contact us by email, phone, or text and we can help you answer any questions you may have about purchasing a plan.',
  small = false,
  className,
}) => {
  return (
    <>
      {small ? (
        <MiniCard className={className}/>
      ) : (
        <Card
          className={`flex flex-col gap-1 p-6 rounded-lg border border-slate-200 ${className}`}
        >
          <h2 className='text-xl font-bold'>{title}</h2>
          <Text className='text-base mb-4'>{children}</Text>

          <MiniCard />
        </Card>
      )}
    </>
  );
};

export default ContactCard;
