import { Flex, Text } from '@chakra-ui/react';
import ClipboardButton from 'components/shared/clipboard-button';
import LogoImage from 'common/components/logo-image';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';

const PlanInfoCard = ({
  plan,
  member,
  selectedCancelAnytime = false,
  selectedLeaveAnytime = false,
}) => {
  // DATA INITIALIZATION
  const address = plan?.lease?.address;
  const name = member?.name || `${member?.firstName} ${member?.lastName}`;
  const property = plan?.property?.name;

  // Figure out what coverages the plan has
  const hasCancelAnytime =
    plan?.coverage?.cancelAnytime?.payment?.stripe?.id ||
    selectedCancelAnytime;
  const hasLeaveAnytime =
    plan?.coverage?.leaveAnytime?.payment?.onetime?.stripe?.id ||
    plan?.coverage?.leaveAnytime?.payment?.subscription?.stripe?.id || selectedLeaveAnytime;

  const hasCoverage = hasCancelAnytime || hasLeaveAnytime;

  const cancelAnytime = hasCancelAnytime ? plan?.coverage?.cancelAnytime : null;
  const leaveAnytime = hasLeaveAnytime ? plan?.coverage?.leaveAnytime : null;

  const cancelAnytimeDetails = hasCancelAnytime
    ? {
        title: 'Cancel Anytime',
        coveragePeriod: `${formatDateToHumanDate(
          cancelAnytime?.startDate
        )} - ${formatDateToHumanDate(cancelAnytime?.endDate)}`,
        limit: '6 months or $30,000',
      }
    : null;

  const leaveAnytimeDetails = hasLeaveAnytime
    ? {
        title: 'Leave Anytime',
        coveragePeriod: `${formatDateToHumanDate(
          leaveAnytime?.startDate
        )} - ${formatDateToHumanDate(leaveAnytime?.endDate)}`,
        limit: '6 months or $30,000',
      }
    : null;

  const coverages = [cancelAnytimeDetails, leaveAnytimeDetails].filter(Boolean);

  return (
    <Flex className='p-8 bg-[#191923] text-white rounded-lg flex-col gap-6 w-full shadow-md'>
      {/* logo */}
      <Flex className='w-full gap-6 flex-col mb-5 sm:flex-row sm:justify-between sm:items-center'>
        <LogoImage dark={false} small={true} />
        <Flex className='uppercase tracking-widest text-sm font-bold gap-x-4 gap-y-2 flex-col sm:flex-row'>
          <ClipboardButton showIcon={false} text={plan?.id || ''} />
          {plan?.status}
        </Flex>
      </Flex>

      {/* street address and name */}
      <Flex className='flex-col gap-6 w-full'>
        <Flex className='flex-col gap-2'>
          <Text className='uppercase tracking-wider text-xl'>
            {address?.streetAddress1}
            {address?.streetAddress2 && `, ${address?.streetAddress2}`},{' '}
            {address?.city}
            {address?.state && `, ${address?.state}`}, {address?.country}
            {address?.postalCode && `, ${address?.postalCode}`}
          </Text>
          {property && (
            <Text className='uppercase tracking-widest font-medium opacity-70 text-sm'>
              {property}
            </Text>
          )}
        </Flex>
        <Text className='text-xl tracking-wide'>{name}</Text>
      </Flex>

      {/* Coverages */}
      {hasCoverage && (
        <Flex className='flex-col gap-6 w-full sm:flex-row pt-6 border-t border-white/30'>
            {coverages?.map((coverage, index) => (
            <Flex key={index} className='flex-col gap-4 w-full'>
              <Text className='uppercase tracking-wider text-sm opacity-70 font-medium'>{coverage.title}</Text>
              <Flex className='gap-4 flex-col sm:flex-row'>
                <Flex className='flex-col w-full'>
                  <Flex className='uppercase tracking-wider text-sm font-bold'>                  
                    <span>Coverage Period</span>
                  </Flex>
                  <Text className='text-lg uppercase tracking-wider'>
                    {coverage.coveragePeriod}
                  </Text>
                </Flex>
                {/* Not using coverage limit right now */}
                {/* <Flex key={index} className='flex-col w-full'>
                  <Flex className='uppercase tracking-wider text-sm font-bold'>                  
                    <span>Coverage Limit</span>
                  </Flex>
                  <Text className='text-lg uppercase tracking-wider'>
                    {coverage.limit}
                  </Text>
                </Flex> */}
              </Flex>
            </Flex>
            ))}
        </Flex>
      )}
    </Flex>
  );
};

export default PlanInfoCard;
