import React from 'react';

const TextAreaInput = ({
  placeholderText = '',
  name = '',
  onChange,
  value,
  onFocus,
  onBlur,
  disabled,
}) => {
  return (
    <textarea
      placeholder={placeholderText}
      name={name}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      className='h-40 border-[1.5px] border-[#191923] rounded-lg resize-none p-3 w-full [outline:none] text-base text-[#191923] font-normal placeholder:text-[#6A6C70] placeholder:font-normal placeholder:text-base disabled:bg-inherit disabled:opacity-60'
    />
  );
};

export default TextAreaInput;
