import Button from 'common/components/button';
import BgLayout from 'common/components/bg';
import { FiCheck } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useGetAllPlansQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import { statusEnum } from 'utils/enums';

const ConfirmAccountSuccess = () => {
  // HOOKS
  const navigate = useNavigate();
  const { data: userPlans, isLoading: isFetchingPlans } = useGetAllPlansQuery();

  return (
    <>
      <BgLayout>
        {/* icon */}
        <FiCheck className='text-[54px] text-[#191923]' />
        {/* texts */}
        <div className='flex flex-col text-center gap-10'>
          <h2 className='font-bold text-4xl text-[#191923] mobile:text-2xl'>
            Account Created
          </h2>

          <h4 className='text-[#191923] text-base font-normal'>
            You have successfully completed your registration. Please
            proceed to the login page to access your dashboard.
          </h4>
        </div>

        {/* button */}
        <Button
          onClick={() => {
            let planUrl = `/dashboard/apply-for-plans`;
            let plan;

            if (!isEmpty(userPlans)) {
              plan = userPlans[0];
            }

            if (plan && plan.id && plan.status) {
              planUrl = `${planUrl}?step=${
                plan.status === 'created' ? 1 : 2
              }&planId=${plan?.id}&isEdit=true`;
            }
            navigate(planUrl);
          }}
          disabled={isFetchingPlans}
          title={
            isFetchingPlans
              ? 'Loading...'
              : isEmpty(userPlans)
                ? 'Complete Account Signup'
                : 'Complete signup'
          }
        />
      </BgLayout>
    </>
  );
};

export default ConfirmAccountSuccess;
