import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import Button from 'common/components/button';
import { iconPositionEnum } from 'common/utils/enums';

const ApplyButtons = ({
  onBackClick,
  onNextClick,
  handleSubmit,
  moveOn,
  loading,
  isValid,
  disableBack = false,
  disableNext = false,
  fullHeight = false,
  backTitle = 'Back',
  nextTitle = 'Next',
}) => {
  return (
    <div className={`flex gap-3 items-center z-50 mobile:fixed mobile:right-5 mobile:bottom-6 ${fullHeight && 'mt-auto pb-10'}`}>
      <Button
        disabled={disableBack}
        onClick={onBackClick}
        Icon={BiLeftArrowAlt}
        iconPosition={iconPositionEnum.LEFT}
        theme='secondary'
        title={backTitle}
        className={disableBack ? 'hidden' : 'pr-6 mobile:shadow-md'}
      />
      <Button
        loading={loading}
        disabled={loading || !isValid || disableNext}
        onClick={moveOn ? onNextClick : handleSubmit}
        Icon={BiRightArrowAlt}
        title={nextTitle}
        className={disableNext ? 'hidden' : 'pl-6 mobile:shadow-md'}
      />
    </div>
  );
};

export default ApplyButtons;
