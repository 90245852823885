import { Formik } from 'formik';
import { useRef } from 'react';
import { useSubmitClaimMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { toast } from 'react-toastify';
import ApplyButtons from 'layouts/apply-buttons';
import { ApplyFormContainer, ApplyFormField, ApplyFormLayout } from 'layouts/apply-form';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Radio, RadioGroup, Tag, Text } from '@chakra-ui/react';
import WrapInputLabel from 'components/shared/wrap-input-label';
import SelectInput from 'common/components/select-input';
import FormError from 'components/shared/form-error';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { extractErrorMsg } from 'utils/errors';
import ContactCard from 'components/shared/contact-card';
import Input from 'components/shared/input';
import TextAreaInput from 'components/shared/text-area-input';
import { claimPaymentPreferenceEnum, claimTypeEnum } from 'utils/enums';
import { toDatePickerFormat } from 'utils/date';

const Edit = ({ claim }) => {
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const [
    submitClaim,
    { data: submittedClaimData, isLoading: submitClaimLoading },
  ] = useSubmitClaimMutation();
  const {
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
    trackCustomEvent,
  } = useUserTracker();


  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        claimId: claim.id,
        claimType: claim.claimType,
        submission: {
          moveReason: claim?.submission?.moveReason,
          moveOutStart: claim?.submission?.moveOutStart,
          moveOutEnd: claim?.submission?.moveOutEnd,
          paymentPreference: claim?.submission?.paymentPreference,
        },
      }}
      validationSchema={Yup.object().shape({
        claimType: Yup.string().required('Required'),
        submission: Yup.object().shape({
          moveReason: Yup.string().required('Required'),          
          paymentPreference: Yup.string().required('Required'),
          moveOutStart: Yup.date().when('claimType', {
            is: (val) => val === claimTypeEnum.leaveAnytime,
            then: Yup.date().required('Required'),
          }),
          moveOutEnd: Yup.date().when('claimType', {
            is: (val) => val === claimTypeEnum.leaveAnytime,
            then: Yup.date().required('Required'),
          }),
        }),  
      })}
      onSubmit={(values) => {
        let submissionValues = {
          claimId: claim.id,
          claimType: values.claimType,
          submission: {
            moveReason: values.submission.moveReason,
            moveOutStart: values.submission.moveOutStart,
            moveOutEnd: values.submission.moveOutEnd,
            paymentPreference: values.submission.paymentPreference,
          },
        }

        trackFormSubmission(submissionValues);

        submitClaim(submissionValues)
          .unwrap()
          .then(() => {
            navigate(`/dashboard/claims/${claim.id}`);
            trackFormSubmissionSuccess(submissionValues);
          })
          .catch((error) => {
            const errMsg = extractErrorMsg(error, 'Submit Failed');
            toast.error(errMsg);
            trackFormSubmissionError(submissionValues, errMsg);
          });
      }}
    >
      {({
        handleBlur,
        handleChange,
        setFieldValue,
        values,
        handleSubmit,
        isValid,
        dirty: isDirty,
        setFieldTouched,
      }) => {
        return (
          <>
            {/* Header Area */}
            <div className='flex gap-4 w-full p-10 mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
              <div className='flex flex-col gap-2 w-full mobile:gap-1'>
                <h1 className='text-3xl mobile:text-2xl font-bold'>
                  Claim Details
                </h1>
                <p className='font-medium text-xl mobile:text-lg'>
                  Please let us know more about your claim details
                </p>
              </div>
              
              {/* Buttons */}
              <ApplyButtons
                loading={submitClaimLoading}
                isValid={isValid && isDirty}
                moveOn={false}
                handleSubmit={handleSubmit}
                nextTitle='Submit Claim'
                disableBack
              />              
            </div>

            {/* Main Area */}
            <div className='flex flex-col h-full w-full gap-12 p-10 overflow-scroll no-scrollbar mobile:px-2 mobile:pb-32'>

              {/* container */}
              <ApplyFormContainer>                
                <ApplyFormLayout>
                  {/* Only show move out window for leave anytime */}
                  {claim.claimType === 'leaveAnytime' && (
                    <>
                      <Flex className='col-span-2 flex-col gap-2'>
                        <Text className='font-semibold col-span-2'>
                          What is the earliest possible and latest possible date you can move out?
                        </Text>
                        <Text className='col-span-2'>
                          You are responsible for paying rent until the month immeidately following your move-out date. For example, if your move-out date is on Oct 25, you are responsible for paying rent until Nov 1.
                        </Text>
                      </Flex>

                      <ApplyFormField small>
                        <WrapInputLabel isRequired>Earliest Date</WrapInputLabel>
                        <Input
                          placeholderText='Select date'
                          type='date'
                          value={values.submission.moveOutStart}
                          onChange={(event) => {
                            handleChange(event);
                            setFieldTouched('submission.moveOutStart');
                          }}
                          onBlur={handleBlur}
                          name='submission.moveOutStart'
                          min={toDatePickerFormat(new Date(new Date().setDate(new Date().getDate() + 7)))}           
                        />
                        <FormError name='submission.moveOutStart' />
                      </ApplyFormField>

                      {/* Lease End Date */}
                      <ApplyFormField small>
                        <WrapInputLabel isRequired>Latest Date</WrapInputLabel>
                        <Input
                          placeholderText='Select date'
                          type='date'
                          value={values.submission.moveOutEnd}
                          onChange={(event) => {
                            setFieldTouched('moveOutEnd');
                            handleChange(event);
                          }}
                          onBlur={handleBlur}
                          name='submission.moveOutEnd'
                          min={toDatePickerFormat(new Date(new Date().setDate(new Date().getDate() + 7)))}
                          max={toDatePickerFormat(claim?.planId?.lease?.endDate)}
                        />
                        <FormError name='submission.moveOutEnd' />
                      </ApplyFormField>
                    </>
                  )}

                  <ApplyFormField>
                    <WrapInputLabel isRequired>Reason for claim</WrapInputLabel>
                    <TextAreaInput
                      placeholderText={
                        claim.claimType === 'leaveAnytime' ? 'Please let us know why you are moving out early' : 'Please let us know why you are cancelling your upcoming lease'
                      }
                      value={values.submission.moveReason}
                      onChange={(event) => {
                        handleChange(event);
                        setFieldTouched('submission.moveReason');
                      }}
                      onBlur={handleBlur}
                      name='submission.moveReason'
                    />
                    <FormError name='submission.moveReason' />
                  </ApplyFormField>

                  <ApplyFormField>
                    <WrapInputLabel isRequired>Payment Preference</WrapInputLabel>

                    <RadioGroup
                      className='flex flex-col gap-4 pt-2'
                      onChange={(option) => {
                        if (option) {
                          setFieldValue('submission.paymentPreference', option).then(() => {
                            setFieldTouched('submission.paymentPreference');
                          });
                        } else {
                          // Handle case when select is cleared
                          setFieldValue('submission.paymentPreference', null).then(() => {
                            setFieldTouched('submission.paymentPreference');
                          });
                        }  
                      }}
                    >
                        <Flex className='rounded-md border border-slate-200 divide-y divide-slate-200 p-4'>
                          <Radio value={claimPaymentPreferenceEnum.DIRECT} size='lg'>
                            <Text className='ml-2'>
                              Direct payment to my landlord
                              <Tag className='ml-4 font-semibold' colorScheme='green'>Recommended</Tag>
                            </Text>
                          </Radio>
                        </Flex>
                      
                        {claim?.planId?.lease?.terminationPolicy?.buyout && (
                          <Flex className='rounded-md border border-slate-200 divide-y divide-slate-200 p-4'>
                            <Radio value={claimPaymentPreferenceEnum.REIMBURSEMENT} size='lg'>
                              <Text className='ml-2'>
                                Reimburse my lease buyout penalty payment
                              </Text>
                            </Radio>
                          </Flex>   
                        )}                
                    </RadioGroup>

                    <Accordion 
                      // defaultIndex={!savedPlansValue.property && [0]}
                      allowToggle className='border rounded-md mt-8'
                    >
                      <AccordionItem className='border-t-0 border-b rounded-b-md'>
                        <AccordionButton className='flex w-full gap-2 justify-between py-3'>
                          <Text className='font-semibold text-base text-left'>
                            Need help submitting a claim?
                          </Text>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel className='border-t pt-5'>
                          <Text className='text-base mb-3'>
                            If you need help submitting a claim, please reach out to us directly.
                          </Text>
                          {/* Can't find property */}
                          <ContactCard small />
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>

                    <FormError name='submission.paymentPreference' />
                  </ApplyFormField>
                </ApplyFormLayout>
              </ApplyFormContainer>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default Edit;
