import Modal from 'react-modal';
import './index.css';

const ModalLayout = ({ modalIsOpen, toggleModal, children }) => {
  return (
    <div className='div-modal'>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        contentLabel='My dialog'
        className='m1y-modal'
        overlayClassName='modal-overlay'
        closeTimeoutMS={500}
        ariaHideApp={false}
      >
        {/* modal content */}
        {children}
      </Modal>
    </div>
  );
};

export default ModalLayout;
