import { Card, Divider, Flex, HStack, Skeleton, Text } from '@chakra-ui/react';
import Button from 'common/components/button';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import StatusLabel from 'components/shared/status-label';
import DownloadPdfButton from 'components/plans/download-button';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useGetSinglePlanQuery,
  useRetrieveUserDetailsQuery,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import {
  clearPlansDraft,
  selectPaymentMode,
  selectPlansValues,
} from 'redux/features/plans/plansSlice';
import {
  coverageTypes,
  currency,
  planPaymentMode,
  statusEnum,
} from 'utils/enums';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { isEmpty } from 'common/utils/isEmpty';
import { currencyFormatter } from 'utils/currency';
import PaymentSummary from 'components/plans/payment-summary';
import ClipboardButton from 'components/shared/clipboard-button';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import PlanInfoCard from 'components/plans/plan-info-card';
import { setUserDetails } from 'redux/features/auth/authSlice';
import PaymentSummaryCard from 'components/plans/payment-summary-card';
import { DisclosureCard } from 'components/coverages/disclosure-card';
import DetailCard from 'components/coverages/detail-card';
import ApplyButtons from 'layouts/apply-buttons';
import { CoverageCard, CoverageDetail } from 'components/coverages/coverage-card';
import generalLimits from 'components/coverages/general-limits';

const ActivePlanReg = ({
  stepNumber,
  onBackClick,
  onNextClick,
  isGettingPlan,
}) => {
  const { trackPageLoad, trackLink } = useUserTracker();
  useEffect(() => {
    trackPageLoad('ActivePlanReg');
  }, []);

  // SIDE EFFECTS
  useEffect(() => {
    if (isRetrieveUserDetailsSuccess && !isEmpty(userDetails)) {
      dispatch(setUserDetails(userDetails));
    }
  }, []);

  // HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const plansValue = useSelector(selectPlansValues);
  const { data: singlePlan, isLoading: isGettingSinglePlan } =
    useGetSinglePlanQuery(plansValue?.id, {
      skip: isEmpty(plansValue?.id),
    });
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  // DATA INITIALIZATION
  const hasCancelAnytime =
    singlePlan?.coverage?.cancelAnytime?.payment?.stripe?.id;
  const hasLeaveAnytime =
    singlePlan?.coverage?.leaveAnytime?.payment?.onetime?.stripe?.id ||
    singlePlan?.coverage?.leaveAnytime?.payment?.subscription?.stripe?.id;
  const hasCoverage = hasCancelAnytime || hasLeaveAnytime;

  console.log('singlePlan', singlePlan);

  return (
    <>
      <div className='flex gap-4 w-full p-10 mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
        <div className='flex flex-col gap-2 w-full mobile:gap-1'>
          <h1 className='text-3xl mobile:text-2xl font-bold'>
            Review in Progress
          </h1>
          <p className='font-medium text-xl mobile:text-lg'>
            We’re reviewing your details and will update you shortly.
          </p>
        </div>

        {/* Buttons */}
        <ApplyButtons
          isValid={true}
          handleSubmit={() => {
            navigate('/dashboard/plans');
            dispatch(clearPlansDraft());
          }}
          onNextClick={() => {
            navigate('/dashboard/plans');
            dispatch(clearPlansDraft());
          }}
          disableBack
          nextTitle='Home'
        />
      </div>

      {/* main content */}
      <div className='flex flex-col h-full w-full gap-12 p-10 overflow-scroll no-scrollbar mobile:px-2 mobile:pb-32'>
        <Flex w='100%' flexDir='column' gap={10}>
          {isGettingSinglePlan || isGettingPlan ? (
            <Flex flexDir='column' gap='20px'>
              <Skeleton rounded='10px' h='10dvh' w='100%' />
              <Flex w='100%' gap='20px'>
                <Skeleton rounded='10px' h='40dvh' w='100%' />
                <Skeleton rounded='10px' h='40dvh' w='100%' />
              </Flex>
              <Skeleton rounded='10px' h='40dvh' w='100%' />
              <Skeleton rounded='10px' h='40dvh' w='100%' />
            </Flex>
          ) : isEmpty(singlePlan) ? (
            <Flex
              border='1px solid'
              borderColor='gray.300'
              rounded='12px'
              w='100%'
              alignItems='center'
              justifyContent='center'
              h='60dvh'
            >
              <Text fontWeight={600} fontSize='20px'>
                No plan found!
              </Text>
            </Flex>
          ) : (
            <>
              {/* container */}
              <Flex className='w-full flex-col gap-10'>
                {/* plan card and info */}
                <Flex className='flex-col gap-6'>
                  {/* plan heading and status */}
                  <Flex className='flex-col gap-4 w-full'>
                    <Flex className='gap-4 items-center justify-between'>
                      <h2 className='text-2xl font-bold'>Your Plan Details</h2>
                      <StatusLabel status={singlePlan?.status} />
                    </Flex>
                  </Flex>

                  {/* plan info card */}
                  <Flex className='flex-col gap-4 w-full'>
                    <PlanInfoCard plan={singlePlan} member={userDetails} />
                  </Flex>

                  <PaymentSummaryCard plan={singlePlan} complete={true} />
                </Flex>

                {/* plan coverages section */}
                {hasCoverage ? (
                  <Flex className='flex-col gap-6 mt-6'>
                    {/* coverages heading */}
                    <Flex className='flex-col gap-1 w-full items-left'>
                      <h2 className='text-2xl font-bold'>Plan Coverages</h2>
                      <Text>
                        Your plan includes the following coverages, limits,
                        deductibles, and exclusions.
                      </Text>
                    </Flex>

                    {/* coverages */}
                    {hasCancelAnytime && (
                      <CoverageCard
                        coverageType={coverageTypes.CANCEL_ANYTIME}
                        startDate={singlePlan.coverage.cancelAnytime.startDate}
                        endDate={singlePlan.coverage.cancelAnytime.endDate}
                      >
                        {generalLimits}
                      </CoverageCard>
                    )}
                    {hasLeaveAnytime && (
                      <CoverageCard 
                        coverageType={coverageTypes.LEAVE_ANYTIME}
                        startDate={singlePlan.coverage.leaveAnytime.startDate}
                        endDate={singlePlan.coverage.leaveAnytime.endDate}
                      >
                        {generalLimits}

                        {/* Elimination Period / Minimum Required Stay */}
                        <CoverageDetail
                          title='Required Stay'
                          limit={['one month', '1/12 of the lease term']}
                        >
                          The required stay is the minimum amount of time you must wait once your coverage period begins before you are eligible to leave early and receive benefits. For example, if your required stay is one month, you must wait one month after your coverage period begins before you are eligible to leave early and receive benefits.
                        </CoverageDetail>
                      </CoverageCard>
                    )}
                  </Flex>
                ) : null}
              </Flex>
            </>
          )}
        </Flex>
      </div>
    </>
  );
};

export default ActivePlanReg;
