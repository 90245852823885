export function objectToFormData(obj) {
  // initialize new formData
  const formData = new FormData();

  // define 'apendFormData' handler
  function appendFormData(data, path) {
    if (Array.isArray(data)) {
      data.forEach((value, index) => {
        appendFormData(value, `${path}[${index}]`);
      });
    } else if (typeof data === 'object' && data !== null) {
      Object.keys(data).forEach((key) => {
        if (data[key] instanceof File) {
          formData.append(`${path}${key}`, data[key]);
        } else {
          appendFormData(data[key], path ? `${path}.${key}` : key);
        }
      });
    } else {
      formData.append(path, data);
    }
  }

  // final append
  appendFormData(obj, '');

  // return formData
  return formData;
}
