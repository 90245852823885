import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';

const NavigationListener = () => {
  const location = useLocation();
  const [oldLink, setOldLink] = useState(null);
  const { trackNav } = useUserTracker();

  useEffect(() => {
    trackNav({
      oldPath: oldLink,
      newPath: location.pathname,
    });
    setOldLink(location.pathname);
  }, [location]);

  return null; // This component doesn't render anything.
};

export default NavigationListener;
