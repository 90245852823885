import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { ciosUsersApi } from './api/ciosUsersApi/ciosUsersApi';
import { rootReducer } from './features';
import { authSlice } from './features/auth/authSlice';
import { claimsSlice } from './features/claims/claimsSlice';
import { plansSlice } from './features/plans/plansSlice';

// redux-persist config
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [authSlice.name, plansSlice.name, claimsSlice.name],
  stateReconciler: autoMergeLevel2,
};

// persist reducers
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(ciosUsersApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

// listen for special rtk query actions
setupListeners(store.dispatch);
