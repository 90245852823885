import React from 'react';
import {
  Card,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Text,
  Flex,
} from '@chakra-ui/react';

const PaymentSummary = ({ claim, isComplete }) => {
  const deductible = claim?.memberPayment?.deductible;
  const nonEligibleCharges = claim?.memberPayment?.nonEligibleCharges;  
  const total = deductible + nonEligibleCharges;

  return (
    <Flex
      className={`flex-col gap-1 text-base`}
    >
      {/* due now text */}
      <Text className='font-bold text-lg'>
        Itemized Charges
      </Text>
      <Text className='mb-4'>
        {isComplete ? 'Your payment receipt includes the following charges:' : 'Your payment includes the following charges:'}
      </Text>
      
      <Flex className={`flex-col gap-1 text-base border border-slate-300 rounded-md p-4 pb-3`}>
        <div className='grid grid-cols-[1fr_auto_60px] gap-2 w-full justify-between sm:grid-cols-[200px_1fr_auto_60px]'>
          <Text className='font-medium'>Deductible</Text>
          <Text className='hidden sm:block'>The portion of the claim cost you’re responsible for paying before coverage applies.</Text>
          <Text>$</Text>
          <Text className='text-right'>{deductible.toFixed(2)}</Text>
        </div>

        <div className='grid grid-cols-[1fr_auto_60px] gap-2 w-full justify-between sm:grid-cols-[200px_1fr_auto_60px]'>
          <Text className='font-medium'>Non-Eligible Charges</Text>
          <Text className='hidden sm:block'>Non-eligible charges owed to your landlord to approve an early move-out, including leasing concessions and certain move-out fees, that are not covered by ReLease.</Text>
          <Text>$</Text>
          <Text className='text-right'>{nonEligibleCharges.toFixed(2)}</Text>
        </div>

        {/* tax */}
        <div className='grid grid-cols-[200px_1fr_auto_60px] gap-2 w-full justify-between pt-2 mt-2 border-t border-slate-300'>
          <Text className='font-medium'>Applicable Taxes</Text>
          <Text></Text>
          <Text>$</Text>
          <Text className='text-right'>0.00</Text>
        </div>

        {/* Total */}
        <div className='grid grid-cols-[200px_1fr_auto_60px] gap-2 w-full justify-between font-bold'>
          <Text>Total</Text>
          <Text></Text>
          <Text>$</Text>
          <Text className='text-right'>{total.toFixed(2)}</Text>
        </div>
      </Flex>
    </Flex>
  )
}

const ClaimsPaymentSummaryCard = ({ 
  claim,
  isComplete=false
}) => {
  const plan = claim?.planId;
  const total = claim?.memberPayment?.deductible + claim?.memberPayment?.nonEligibleCharges;

  if (total === 0) {
    return null;
  } else {
  return (
    <Card className='flex flex-col rounded-lg border border-slate-200'>
      <Accordion
        allowMultiple
        className='flex w-full flex-col  rounded-md overflow-hidden'
      >
        <AccordionItem className='border-none'>
          <div className='p-6 flex mobile:flex-col gap-6 justify-between items-center mobile:items-start'>
            <div className='flex flex-col gap-1'>
              <h2 className='capitalize text-2xl font-bold'>
                {isComplete ? 'Payment Receipt' : 'Payment Details'}
              </h2>
              <Text className='text-base'>
                Here’s a summary of your payment to finalize your claim resolution.
              </Text>
            </div>

            {/* Quick view of monthly/onetime totals */}
            <Flex className='gap-5 divide-x divide-slate-200 items-center'>
              <div className='flex flex-col items-end mobile:items-start'>
                <div className='text-2xl font-medium'>
                  ${total.toFixed(2)} {plan?.lease?.currency}
                </div>
                <div className='text-base font-normal'>
                  Total Payment
                </div>
              </div>
            </Flex>
          </div>

          {/* Accordion to expand to show all */}
          <AccordionButton className='px-6 py-3 hover:bg-slate-100 border-t border-slate-200'>
            <div className='w-full text-left text-sm font-semibold py-1 uppercase'>
              Show Expanded Payment {isComplete ? 'Receipt' : 'Details'}
            </div>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel className='border-t p-6 flex flex-col gap-8'>
            {/* payment information */}
            <PaymentSummary claim={claim} isComplete={isComplete} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Card>
  );
}};

export default ClaimsPaymentSummaryCard;
