import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WrapInputLabel from 'components/shared/wrap-input-label';
import { statusEnum } from 'utils/enums';
import { useGetAllPlansQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import SelectInput from 'common/components/select-input';
import StatusLabel from 'components/shared/status-label';
import { useCreateClaimMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import ApplyButtons from 'layouts/apply-buttons';
import { ApplyFormContainer, ApplyFormField, ApplyFormLayout } from 'layouts/apply-form';
import { Text } from '@chakra-ui/react';

const StartClaim = () => {
  const navigate = useNavigate();
  // HOOKS
  const { data: plansData, isLoading: getPlansLoading } = useGetAllPlansQuery();
  const [createClaim, { data: claimData, isLoading: createClaimLoading }] =
    useCreateClaimMutation();
  const [planId, setPlanId] = useState(null);

  // HANDLERS
  const createPlansOptionMenu = (obj) => {
    // Check if obj is empty
    if (isEmpty(obj)) return [];

    // Remove items in obj if their status is not active
    const filteredItems = obj.filter(item => item.status === statusEnum.active);        

    // Check if filteredItems is empty
    if (isEmpty(filteredItems)) return [];   

    return filteredItems?.map(
      // eslint-disable-next-line no-unused-vars
      ({ createdAt, id, status, lease, ...res }) => ({        
        label: (
          <div className='w-full flex py-1 justify-between flex-col sm:gap-2 sm:flex-row sm:items-center'>
            <div className='text-base font-semibold'>
              Plan {id.toUpperCase()}
            </div>
            <div className='flex gap-2 items-center w-full justify-between sm:w-auto'>
              {lease?.address && (
                <div className='text-base font-normal'>
                  {lease?.address?.streetAddress1}, {lease?.address?.city}
                </div>
              )}
              <StatusLabel status={status} size='sm' />
            </div>
          </div>
        ),
        value: id,
      })
    );
  };

  return (
    <>
      {/* Header Area */}
      <div className='flex gap-4 w-full p-10 mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
        <div className='flex flex-col gap-2 w-full mobile:gap-1'>
          <h1 className='text-3xl mobile:text-2xl font-bold'>
            Select Plan
          </h1>
          <p className='font-medium text-xl mobile:text-lg'>
            Pick the plan for the lease you are submitting a claim for.
          </p>
        </div>
        
        {/* Buttons */}
        <ApplyButtons
          isValid={planId}
          moveOn={true}
          onNextClick={() => {
            console.log('planId before createClaim:', planId); // Debug value
            createClaim({
              value: { planID: planId }
            })
              .unwrap()
              .then((x) => {
                navigate(`/dashboard/apply-for-claims/${x?.data?.id}`);
              });
          }}
          disableBack
        />
      </div>

      {/* Main Area */}
      <div className='flex flex-col h-full w-full gap-12 p-10 overflow-scroll no-scrollbar mobile:px-2 mobile:pb-32'>

        {/* container */}
        <ApplyFormContainer>

          {/* select plan */}
          <ApplyFormLayout>
            <Text className='font-semibold col-span-2'>
              Select Plan from List
            </Text>
            <ApplyFormField>
              <WrapInputLabel isRequired>Plan</WrapInputLabel>

              <SelectInput
                options={createPlansOptionMenu(plansData)}
                isDisabled={isEmpty(plansData) || getPlansLoading}
                placeholder={
                  getPlansLoading
                    ? 'Fetching...'
                    : isEmpty(plansData)
                      ? `No available plan, please create a new one.`
                      : 'Type to search for a plan by number'
                }
                name='planId'
                value={createPlansOptionMenu(plansData).find(
                  (selectedPlan) => selectedPlan.value === planId
                )}
                onChange={(selectedOption) => {                 
                  setPlanId(selectedOption.value);
                }}
                isOptionDisabled={(option) => {
                  const optionID = option.value;
                  const optionFullObject = plansData.find(
                    (plan) => plan.id === optionID
                  );
                  return optionFullObject?.status !== statusEnum.active;
                }}
              />
            </ApplyFormField>
          </ApplyFormLayout>
        </ApplyFormContainer>
      </div>
    </>
  );
};

export default StartClaim;
