import AnchorLinkTag from 'components/shared/anchor-link-tag';
import { useEffect, useMemo, useState } from 'react';
import { TiHome } from 'react-icons/ti';
// import { Link } from 'react-router-dom';
import { POLLING_INTERVAL, statusEnum } from 'utils/enums';
import PlansTable from 'components/plans/plans-table';
import Button from 'common/components/button';
import LoadingSpinner from 'components/shared/loading-spinner';
import DashboardMainLayout from 'layouts/dashboard-main';
import TablePaginateWrapper from 'layouts/table-paginate-wrapper';
import {
  useGetAllPlansQuery,
  useRetrieveUserDetailsQuery,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import { Link } from 'common/utils/user-tracker/react-router-dom';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import useTracker from 'common/utils/user-tracker/useTracker';
import EmptyPlans from 'components/plans/empty-plans';
import { useNavigate } from 'react-router-dom';
import { Flex, Text } from '@chakra-ui/react';
import PlanInfoCard from 'components/plans/plan-info-card';
import DownloadPdfButton from 'components/plans/download-button';
import { Icon } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa6';

const Plans = () => {
  // DATA INITIALIZATION
  const pageSize = 10;
  const navigate = useNavigate();

  // STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [pollingInterval, setPollingInterval] = useState(
    () => POLLING_INTERVAL
  );

  // HOOKS
  const { data: plansData, isLoading: getAllPlansLoading } =
    useGetAllPlansQuery(undefined, {
      pollingInterval: pollingInterval,
      sort: '-createdAt',
      limit: pageSize,
    });
  const { trackLink } = useUserTracker();
  useTracker({ pageId: 'plansList' });
  const [minPageLimit, maxPageLimit] = useMemo(() => {
    const minPageLimit = (currentPage - 1) * pageSize;
    const maxPageLimit = currentPage * pageSize - 1;
    return [minPageLimit, maxPageLimit];
  }, [currentPage]);
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  // USE EFFECT
  useEffect(() => {
    if (isEmpty(plansData)) {
      setPollingInterval(0);
    } else {
      setPollingInterval(POLLING_INTERVAL);
    }
  }, [plansData]);

  // Filter plansData down to only the plans that are not active, cancelled, or complete.
  const filteredPlansData = useMemo(() => {
    if (isEmpty(plansData)) return [];
    return plansData.filter(
      (plan) =>
        plan.status !== statusEnum.cancelled &&
        plan.status !== statusEnum.active &&
        plan.status !== statusEnum.complete
    );
  }, [plansData]);

  // Filter plansDAta down to only the active plans
  const activePlans = useMemo(() => {
    if (isEmpty(plansData)) return [];
    return plansData.filter((plan) => plan.status === statusEnum.active);
  }, [plansData]);

  return (
    <DashboardMainLayout title='Plans'>
      <div className='flex flex-col w-full gap-6 mobile:h-full'>
        {/* breadcrumb */}
        <div className='flex gap-x-3  gap-y-1 items-center flex-wrap'>
          <Link
            to='/dashboard/home'
            linkId={'plansDashboardHomeBtn'}
            className='font-normal text-base text-[#CED0CE] mobile:hidden'
          >
            Home
          </Link>
          <Link
            to='/dashboard/home'
            linkId={'plansDashboardHomeBtn'}
            className='font-normal text-base text-[#CED0CE] hidden mobile:inline'
          >
            <TiHome className='text-2xl' />
          </Link>
          <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
          <span className='text-[#191923] text-base font-normal'>Plans </span>
        </div>

        {/* buttons */}
        <div className='flex w-full gap-4 mobile:flex-col'>
          <Link
            className='w-full'
            to='/dashboard/apply-for-plans'
            linkId='Apply For A Plan'
          >
            <Button title='Apply For A Plan' />
          </Link>
          <AnchorLinkTag
            className='w-full'
            hrefPath='mailto:members@releaserent.com'
          >
            <Button
              theme='secondary'
              title='Chat with an Expert'
              onClick={() => {
                trackLink('Chat with an Expert');
              }}
            />
          </AnchorLinkTag>
        </div>

        {getAllPlansLoading ? (
          <LoadingSpinner />
        ) : isEmpty(plansData) ? (
          <EmptyPlans />
        ) : (
          <>
            {/* active plans */}
            <Flex className='flex-col gap-8 mb-8'>
              {/* plan heading and status */}
              <Flex className='flex-col gap-4 w-full pt-8'>
                <Flex className='gap-4 items-center justify-between'>
                  <h2 className='text-2xl font-bold'>Your Active Plans</h2>
                </Flex>
                {/* <Text>
                  Your coverage plan gives you the flexibility to move any time
                  between your lease start and end date. For full details on your coverage plan please visit the <AnchorLinkTag className='underline hover:opacity-80 transition-all' hrefPath='https://www.releaserent.com/legal/lease-flexibility-coverage-terms-conditions'>lease flexibility coverage terms and conditions</AnchorLinkTag> on our website.
                </Text> */}
              </Flex>

              {activePlans.map((plan, index) =>
                <Flex index={index} className='flex-col gap-4 w-full'>
                  <PlanInfoCard
                    plan={plan}
                    member={userDetails}
                  />
                  <Flex className='gap-4 mobile:gap-2 mobile:flex-col'>
                    <Button
                      className='cursor-pointer hover:opacity-80 transition-all'
                      onClick={() =>
                        navigate(`/dashboard/plans/${plan.id}`)
                      }
                      title='View Plan Details'
                    />
                    {plan.id && <DownloadPdfButton planId={plan.id} />}
                  </Flex>
                </Flex>
              )}
            </Flex>

            {/* applications */}
            <Flex className='flex-col gap-8'>
              {/* plan heading and status */}
              <Flex className='gap-4 items-center justify-between'>
                <h2 className='text-2xl font-bold'>Your Applications</h2>
              </Flex>

              {/* table */}
              <TablePaginateWrapper
                pageSize={pageSize}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalCount={filteredPlansData?.length}
              >
                <PlansTable
                  plans={filteredPlansData?.slice(minPageLimit, maxPageLimit)}
                />
              </TablePaginateWrapper>
            </Flex>
          </>
        )}
      </div>
    </DashboardMainLayout>
  );
};

export default Plans;
