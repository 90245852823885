import React from 'react';
import { RotatingLines } from 'react-loader-spinner';

const LoadingSpinner = ({
  heightTailwind = 'h-10',
  spinnerColor = '#191923',
  className
}) => {
  return (
    <div
      className={`w-full ${heightTailwind} flex items-center justify-center ${className}`}
    >
      <RotatingLines
        strokeColor={spinnerColor}
        strokeWidth='4'
        animationDuration='0.75'
        width='40'
        visible={true}
      />
    </div>
  );
};

export default LoadingSpinner;
