import { useContext } from 'react';
import FormDataContext from 'common/utils/user-tracker/context';

const useFormData = () => {
  const context = useContext(FormDataContext);
  if (context === undefined) {
    throw new Error('useFormData must be used within a FormDataProvider');
  }
  return context;
};

export default useFormData;
