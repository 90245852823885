import { Flex, Tag, Text } from '@chakra-ui/react';
import { Radio } from '@chakra-ui/react';
import { planPaymentMode } from 'utils/enums';

const PaymentPlanCard = ({
  value = planPaymentMode.SUBSCRIPTION,
  amount,
  discount,
}) => {
  return (
    <Flex className='p-6 gap-6 w-full flex-col sm:flex-row sm:items-center sm:justify-between'>
      {/* cost section */}
      <Radio value={value} size='lg'>
        <Flex className='ml-3 w-full flex-col'>
          <Text className='text-xl font-semibold'>
            {value === planPaymentMode.SUBSCRIPTION
              ? 'Monthly Payments'
              : 'Upfront Payment'}
          </Text>
          <Text className='text-base'>
            {value === planPaymentMode.SUBSCRIPTION
              ? 'Pay in monthly installments'
              : 'Pay upfront for a discount'}
          </Text>
        </Flex>
      </Radio>

      {/* Price section */}
      <Flex className={`flex flex-col gap-2 ml-9 ${value === planPaymentMode.ONE_TIME && 'sm:-mb-4'}`}>
        {value === planPaymentMode.ONE_TIME && (
          <Tag className='w-fit h-fit flex py-1 px-4 rounded-full bg-slate-100 text-sm font-semibold uppercase tracking-wide sm:-mt-4'>
            Save {(discount * 100)?.toFixed(2)}%
          </Tag>
        )}
        <Text>
          <span className='align-baseline text-2xl font-medium'>${amount}</span>
          <span className='align-baseline text-base'>  /mo</span>
        </Text>        
      </Flex>
    </Flex>
  );
};

export default PaymentPlanCard;
