/* eslint-disable no-unused-vars */
import { useRetrieveUserDetailsQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import EmptyData from 'components/shared/empty-data';
import LoadingSpinner from 'components/shared/loading-spinner';
import NameEmailForm from 'components/settings/name-email-form';
import PasswordForm from 'components/settings/password-form';

const AccountInformationSettings = () => {
  // HOOKS
  const { data: userDetails, isLoading: getUserDetailsLoading } =
    useRetrieveUserDetailsQuery();

  return getUserDetailsLoading ? (
    <LoadingSpinner heightTailwind='h-[60dvh]' />
  ) : isEmpty(userDetails) ? (
    <EmptyData />
  ) : (
    <div className='w-full flex flex-col p-2 gap-8 mobile:gap-4'>
      {/* first container */}
      <div className='w-full flex flex-col gap-8 p-6 divide-y divide-slate-300 mobile:w-full mobile:px-4'>
        {/* Contact Information */}
        <NameEmailForm user={userDetails} />

        {/* Reset Password */}
        <PasswordForm />
      </div>
    </div>
  );
};

export default AccountInformationSettings;
