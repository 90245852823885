import PrimaryIcon from "common/images/wordmark-primary.svg";
import SecondaryIcon from "common/images/wordmark-secondary.svg";

// Dark controls whether the primary (black on white / dark=true) or secondary (white on black / dark=false) wordmark is used

const LogoImage = ({ small = false, className = "", dark = true }) => {
  return (
    <img
      className={className}
      src={dark ? PrimaryIcon : SecondaryIcon}
      alt="ReLease Logo"
      width={small ? "150px" : "200px"}
    />
  );
};

export default LogoImage;
