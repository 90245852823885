import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { logout, setCredentials } from 'redux/features/auth/authSlice';
import { useImpersonationTokenMutation } from '../../redux/api/ciosUsersApi/ciosUsersApi';
import { clearClaimsDraft } from 'redux/features/claims/claimsSlice';
import { clearPlansDraft } from 'redux/features/plans/plansSlice';

function View() {
  // DATA INITIALIZATION
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const authToken = searchParams.get('token');
  const planId = searchParams.get('planId');
  const [impersonateToken] = useImpersonationTokenMutation();

  // USE EFFECT
  useEffect(() => {
    if (authToken) {
      dispatch(logout());
      dispatch(clearClaimsDraft());
      dispatch(clearPlansDraft());
      impersonateToken({ token: authToken })
        .unwrap()
        .then(({ data }) => {
          if (!data || !data?.token) {
            return;
          }
          dispatch(
            setCredentials({
              isLoggedIn: true,
              token: data?.token,
              user: data?.user,
            })
          );
          if (planId) {
            // This should get changed to go to the plan details page, i.e. no pdf
            navigate(`/dashboard/plans/${planId}/pdf`);
          } else {
            navigate('/dashboard/home');
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [authToken]);
  return (
    <div className='w-full min-width-[400px] rounded-[10px] gap-5'>
      <div className='flex w-full items-center bg-[#E1E6EF] text-[#191923] text-base font-bold px-5 py-5 rounded-[10px]'>
        Token Login
      </div>
    </div>
  );
}

export default View;
