import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react';
import { Global } from "@emotion/react";

// Do not change the overflow sttings on InfoCard
// These are required to prevent react-select from being cut off in the accordion panel, especially in the admin portal

const InfoCard = ({ title, defaultIndex=[0], allowToggle=true, children, className }) => {
  return (
    <>
      <Global
        styles={`
          .chakra-collapse {
            overflow: visible !important;
          }
        `}
      />
      <Accordion 
        defaultIndex={defaultIndex}
        allowToggle={allowToggle} className={`w-full rounded-md border border-solid gap-4 border-slate-200 overflow-visible ${className}`}
      >
        <AccordionItem className='rounded-t-md overflow-visible'>
          <AccordionButton className='flex w-full justify-between items-center bg-slate-200 text-lg font-semibold px-4 py-3 rounded-t-md'>
            {title}
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel 
            className='border-t p-0 divide-y divide-slate-200 overflow-visible'
          >
            {children}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>    
  );
};

const InfoGrid = ({ children, className, small=false }) => {
  return (
    <div
      className={`grid w-full ${small ? 'grid-cols-2' : 'grid-cols-4 mobile:grid-cols-2'} gap-4 p-4 ${className}`}
    >
      {children}
    </div>
  );
};

const InfoField = ({ title, children, className, onClick, innerClassName }) => {
  return (
    <div className={`flex flex-col gap-1 ${className} ${onClick && 'cursor-pointer'}`} onClick={onClick} >
      <label className="text-xs tracking-wider uppercase font-semibold">
        {title}
      </label>

      <div className={onClick && `underline underline-offset-2 opacity-50 hover:opacity-100 ${innerClassName}`}>{children}</div>
    </div>
  );
};

export { InfoCard, InfoGrid, InfoField };
