import { localeTimeOptions, shortTime } from 'common/utils/enums';
import { isEmpty } from 'common/utils/isEmpty';

export const formatDateToHumanTime = (date) => {
  if (isEmpty(date)) return '-';
  return `${new Date(date).toLocaleString(
    'en-UK',
    localeTimeOptions
  )}, ${shortTime.format(new Date(date))}`;
};

export const formatDateToHumanDate = (date) => {
  if (isEmpty(date)) return '-';
  const parsedDate = new Date(date).toLocaleString('en-US', {
    timeZone: 'UTC',
  });
  return `${new Date(parsedDate).toLocaleString('en-US', localeTimeOptions)}`;
};

export const formatDateToHumanDateShort = (date) => {
  if (isEmpty(date)) return '-';
  const parsedDate = new Date(date).toLocaleString('en-US', {
    timeZone: 'UTC',
  });
  return `${new Date(parsedDate).toLocaleDateString(
    'en-US',
    localeTimeOptions
  )}`;
};

export const monthDiff = (dateFrom, dateTo) => {
  const startDate = new Date(dateFrom);
  const endDate = new Date(dateTo);
  return (
    endDate?.getMonth() -
    startDate?.getMonth() +
    12 * (endDate?.getFullYear() - startDate?.getFullYear())
  );
};
